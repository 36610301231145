.penaltyDynamicContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  .containerCheckbox {
    max-width: 250px;
    overflow-y: auto;
    height: 300px;

    .checkBoxBlock {
      display: flex;
      align-items: flex-start;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
      padding: 6px 6px 6px 0;
    }

    .checkBox {
      padding: 3px 6px 3px 0;
      width: 80px;
    }
  }

  h4 {
    font-size: 18px;

    @media screen and (max-width: 1800px) {
      font-size: 16px;
    }
  }
}

.diagramDecription {
  background-color: white;
  width: 100%;
  padding: 20px;
  box-shadow: -1px 1px 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  .valueContainer {
    margin-left: 11px;
    span {
      font-size: 14px;
    }
  }
  .circleContainer {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .hintTitle {
    font-size: 14px;
    color: #43455c;
    margin-bottom: 10px;
    margin-top: 0;
  }
}

.showDate {
  display: flex;

  gap: 5px;
  flex-direction: column;
}

.scrollableBlock {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.rechartsWrapper {
  max-width: 1150px;
}

.hiddenMessage {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 12px;

  span {
    background: rgb(255, 255, 255);
    padding: 6px 12px;
    box-shadow: -1px 1px 8px 4px rgb(0 0 0 / 10%);
    border-radius: 3px;
    font-weight: 500;
    color: #262626;
  }
}

.scrollableBlock {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.rechartsWrapper {
  max-width: 1150px;
}

