@import "root/css/variables";
@import "root/css/mixins";

.wrap {
  position: relative;
  background-color: #f0f2f5;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
  padding-top: 24px;
  > div {
    background: white;
    padding: 16px;
  }
}

.cell {
  white-space: nowrap;
  border-top: 1px solid $border-color;
  border-right: 1px solid $border-color;
  font-weight: 400;
  padding: 16px 8px;

  &:first-of-type {
    border-left: 1px solid $border-color;
  }
}

.cellweek {
  position: sticky !important;
  z-index: 9 !important;
  left: 0 !important;
  right: 0 !important;
  border: 1px solid $border-color !important;
  background-color: white;
}

.commonSum {
  background-color: #fff !important ;
}

.title {
  color: #747474;
  margin-bottom: 10px;

  @media screen and (max-width: 1800px) {
    font-size: 16px;
  }
}

.borderRight {
  border-right: solid 1px $gun-powder-color;
}

.borderBottom {
  border-bottom: solid 1px $gun-powder-color;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  flex-wrap: wrap;
}

.settingsBlock {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span[class*="-MuiSwitch-track"] {
    background-color: $main-color;
  }
}

.text {
  margin: 0;
  white-space: nowrap;

  @media screen and (max-width: 400px) {
    white-space: wrap;
  }
}

.loader {
  @include loader;
}

.boxLoader {
  background-color: #fff;
  height: 424px;
  position: relative;
}
