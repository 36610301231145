.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;

    .title h4 {
        font-size: 18px;
        margin: 0;

        @media screen and (max-width: 1800px) {
            font-size: 16px;
        }
    }

    svg {
        overflow: visible;
    }

    @media (max-width: 1440px) {
        width: 100%;
        margin: 0 auto;
    }
}

.commissionScrollableBlock {
    overflow: auto;
    height: 100%;
    max-height: 400px;

    //@media (max-width: 1800px) {
    //    height: 550px;
    //}
}


.wrap {
    overflow-y: auto;
    min-width: 0 !important;
    @media (max-width: 600px) {
        min-width: 500px !important;
    }
}

.drawerWrap {
    @media (max-width: 1024px) {
        div[class*="MuiDrawer-paper"] {
            width: calc(100% - 72px);
            overflow-x: auto;
        }
    }

    @media (max-width: 959px) {
        div[class*="MuiDrawer-paper"] {
            width: 100%;
        }
    }
}

.closeIconBlock {
    @media (max-width: 959px) {
        margin-top: 65px;
    }
}

.tableWrap {
    margin: 0 10px 10px 10px;
}

.btnExport {
    text-transform: none;
    background-color: #fff;
    font-weight: 400;
    margin: 0 10px 10px auto;
    width: 250px;
}