@import "root/css/variables.scss";

.root {
	position: relative;
	display: flex;
	align-items: flex-start;
	user-select: none;
	transition: $transition;
	cursor: pointer;
	&[data-aligncenter="true"] {
		align-items: center;
	}
	&[data-disabled="true"] {
		cursor: default;
		.checkbox {
			cursor: default;
		}
	}
	&[data-disabled="true"] .checkbox, &[data-disabled="true"] .subtitle{
		opacity: 0.5;
	}

	&[data-changable="false"] {
		cursor: default;
		pointer-events: none;
	}

	.checkbox {
		padding: 0;
		margin-right: 10px;
	}

	.modal {
		position: absolute;
		left: 30px;
		bottom: -106px;
		width: auto;
		background: white;
		z-index: 10;
		border: 2px solid $main-color;
		border-radius: 0px;
		transition: 0.3s;
	}

	.modalImage {
		min-width: 100%;
		height: 100px;
	}

	.info {
		display: flex;
		flex-direction: column;
		.nameContainer {
			display: flex;

			.name {
				display: flex;
				align-items: center;
				font-size: 14px;
				color: $gun-powder-color;
			}
			.closeIcon {
				margin-right: 4px;
				margin-top: 2px;
				width: 15px;
				height: 15px;
				display: inline;
				cursor: pointer;
				color: $faded-color;
			}
		}
		.subtitle {
			font-size: 12px;
			color: $faded-color;
		}
	}
}
