@mixin loader {
  div[class*="box"] {
    width: 100%;
    padding: 10px 35px;
  }

  div[class*="text"] {
    font-size: 16px !important;
  }

  div[class*="progressWrapper"] {
    padding: 14px !important;
  }
}

@mixin navigationHistory {
  margin-bottom: 6px;

  div[class*="linkWrapper"]:first-of-type {
    a {
      pointer-events: none;
    }
  }

  a[data-active="true"] {
    font-weight: 500 !important;
  }
}