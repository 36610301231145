.scrollBox {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer !important;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cf3aa0;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #cf3aa0;
    border-radius: 3px;
  }

  // div:has(> table) {
  //   max-height: 75vh !important;
  // }
}
.scrollBoxMini {
  div:has(> table) {
    max-height: 75vh !important;
  }
}
