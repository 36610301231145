.svg-circle svg{
  width: 200px !important;
  /*height: 200px !important;*/
  overflow: visible !important;
  /*@media screen and (max-width: 450px) {*/
  /*  width: 390px !important;*/
  /*}*/
}

.svg-circle>div{
  /*height: 200px !important;*/
  width: 200px !important;
  overflow: visible !important;
  margin-right: 20px;
}
