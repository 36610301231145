@import "root/css/mixins";

.commissionStructureContainer {
  background-color: #f0f2f5;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 24px;

  .containerDiagrams {
    gap: 10px;
    display: flex;
    width: 100%;
    //align-items: center;
    grid-area: box2;

    @media screen and (max-width: 1440px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    margin-top: 24px;

    .menuTabable {
      margin-left: 16px;
    }
  }
}
.menuTabable {
  display: flex;
  padding-left: 19px;
  margin-left: 16px;
}

.cardsAndDiagramBox {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  grid-area: box1;
  padding: 20px;
  background-color: white;
  margin-bottom: 24px;

  @media screen and (max-width: 1440px) {
    flex-direction: column;
  }
}

.flexableContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media screen and (max-width: 1800px) {
    flex-direction: column;
  }
}

.leftBlock {
  width: 100%;
  gap: 10px;
  display: grid;
  grid-auto-rows: minmax(30px, auto);
  grid-auto-columns: 1fr 1fr 1fr auto;
  grid-template-areas:
          "box1 box1 box1 box1"
          "box2 box2 box2 box3"
          "box2 box2 box2 box3"
;

  @media screen and (max-width: 1600px) {
    grid-auto-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 1500px){
    grid-template-areas:
          "box1 box1 box1"
          "box2 box2 box3"
          "box2 box2 box3"
  ;
  }
  @media screen and (max-width: 1000px) {
    grid-template-areas:
          "box1 box1 box1"
          "box2 box2 box2"
          "box3 box3 box3"
  ;
  }
}

.commissionTopBox {
  background: white;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  padding: 16px;
  overflow: hidden;
  width: 100%;
  grid-area: box3;

  //@media screen and (max-width: 1800px) {
  //  width: 50vw;
  //  margin: 0 auto;
  //}

  @media screen and (max-width: 1440px) {
    width: 100%;
  }
}

.loader {
  @include loader;
}

.boxLoader {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  min-height: 100px;
}

.widgetSize {
  min-height: 325px;
  min-width: 370px;

  @media screen and (max-width: 1440px) {
    min-height: 86px;
  }

  @media screen and (max-width: 470px) {
    min-width: 100%;
  }
}

.commisionSize {
  min-height: 325px;
  width: 100%;
}

.diagramSize {
  width: 100%;
}
