.cicrleDescription {
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    white-space: wrap;
    color: #798097;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
