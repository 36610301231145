@import "root/css/variables.scss";

// -------------------------------- SELECTOR DRAWER
.drawerInner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: $md) {
    grid-template-columns: 1fr;
    .rightSide {
      display: none;
    }
  }
  .rightSide,
  .leftSide {
    display: grid;
    max-height: 100vh;
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      cursor: pointer !important;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
      background: #cf3aa0;
      border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #cf3aa0;
      border-radius: 3px;
    }
  }
  .drawerHeader {
    border-bottom: 1px solid $border-color;
  }
  .rightSide {
    background-position: center;
    background-repeat: no-repeat;
    border-left: 1px solid $border-color;
    grid-template-rows: 60px 1fr 80px;
    .rightSideContent {
      max-height: 100%;
      overflow-y: auto;
      padding: 1rem;
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
    }
  }
  .leftSide {
    grid-template-rows: max-content 1fr 80px;
    position: relative;
    .drawerContentWrapper {
      max-height: 100%;
      overflow-y: auto;
      padding: 0 1rem;
      .drawerContentItem {
        border-bottom: 1px solid $border-color;
        min-height: 60px;
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      .drawerContentPagination {
        position: absolute;
        bottom: 80px;
        left: 0;
        width: 100%;
        border-top: 1px solid $border-color;
        box-shadow: 0px -37px 65px -20px rgba(34, 60, 80, 0.2);
        background-color: $background-color-total;
      }
    }
  }
  .drawerFooter {
    display: flex;
    gap: 1px;
    align-items: center;
    justify-content: start;
    box-shadow: 0px -37px 65px -20px rgba(34, 60, 80, 0.2);
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
