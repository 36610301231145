.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;

  > div {
    padding-left: 40px;

    @media screen and (max-width: 1800px) {
      padding-left: 65px;
    }

    @media screen and (max-width: 1600px) {
      padding-left: 85px;
    }

    @media screen and (max-width: 1440px) {
      padding-left: 40px;
    }

    @media screen and (max-width: 599px) {
      padding-left: 65px;
    }

    @media screen and (max-width: 480px) {
      padding-left: 105px;
    }

    @media screen and (max-width: 400px) {
      padding-left: 140px;
    }
  }


  h4 {
    font-size: 18px;
    @media screen and (max-width: 1800px) {
      font-size: 16px;
    }
  }

  svg {
    overflow: visible;
  }
}

.smallPadding {
  padding-left: 50px !important;
  padding-right: 20px !important;
  height: 99% !important;
  min-width: 380px;

  > div {
    padding-left: 0 !important;
  }

  @media screen and (min-width: 1920px) {
    padding-left: 95px !important;
  }

  @media screen and (max-width: 1920px) {
    min-width: 430px;
  }
}

.xScrollable {
  overflow-x: auto;
}

.economicStyle {
  padding-left: 50px !important;
  padding-right: 20px !important;
  height: 99% !important;
  min-width: 400px;

  @media screen and (max-width: 1800px) {
    padding-left: 0px !important;
  }

  @media screen and (max-width: 599px) {
    min-width: 490px;
  }

  @media screen and (max-width: 480px) {
    min-width: 400px;
  }

  @media screen and (max-width: 400px) {
    min-width: 330px;
  }
}
