@import "root/css/variables.scss";

.wrapper {
  position: relative;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.inputWrapper {
  display: flex;
  @media screen and (max-width: 600px) {
    border: 1px solid $border-color;
    border-radius: 3px;
  }
}

.rangeInput {
  border: 1px solid $border-color;
  padding: 13px 16px;
  border-radius: 3px;
  font-size: 16px;
  color: #43455c;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-right: 12px;
  @media screen and (max-width: 600px) {
    border: none;
    width: auto;
  }
}

.periodSelect:hover {
  background: #eff2f7;
}

.periodSelect {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  cursor: pointer;
  font-size: 12px;
  color: #000;
  font-weight: 400;
  border-bottom: 1px solid #eff2f7;
  border-right: 1px solid #eff2f7;
}

.rangeInput label {
  position: absolute;
  background: white;
  left: 8px;
  top: -8px;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 5px;
  padding-right: 5px;
  font-size: 11px;
  width: auto;
  height: 11px;
}
.clearButton {
  color: #798097;
  height: 1em;
  width: 1em;
  .clearIcon {
    height: 1em;
    width: 1em;
    font-weight: 700;
  }
}
.leftInput {
  // border-right-width: 0;
  // border-top-right-radius: 0px;
  // border-bottom-right-radius: 0px;
  // border-top-left-radius: 0px;
  // border-bottom-left-radius: 0px;
  padding-right: 24px;
}
.resetIcon {
  color: #000;
}
.leftInput:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 1.5px;
  // background: black;
  white-space: nowrap;
  right: -3px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.rightInput {
  border-left-width: 0;
  margin-left: -1px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.root_dateRange {
  display: flex;
}

.dateRange_box {
  display: flex;
  gap: 1rem;
  background-color: #fff;
  padding-right: 1rem;
  margin-right: 1rem;
  box-shadow: 5px 4px 14px #0000008c; //0 2px 4px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: $md) {
    padding-right: 0;
  }
}

.DefinedRange {
  @media screen and (max-width: $md) {
    width: 100%;
  }
}

.calendarWrapper {
  display: flex;
  min-width: 200px;

  @media screen and (max-width: $md) {
    flex-direction: column-reverse;
  }
}

.dateRange_actions {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 25px;
  padding-left: 1rem;

  .dateRange_actions_box {
    display: grid;
  }

  .dateRange_actions_item:last-child {
    margin-top: 0;
  }

  .dateRange_actions_active_item {
    background: #cf3aa0 !important;
    color: #fff !important;
    padding: 6px 12px;
    border-radius: 5rem;
  }

  .dateRange_actions_item {
    color: #000;
    background: #f8f8f8;
    // padding: 1rem;
    border-radius: 5rem;
    cursor: pointer;
    padding: 6px 12px;
    white-space: nowrap;
    margin-top: 1rem;
  }
}

div:has(> .root_dateRange) {
  // position: absolute;
  // top: -14px;
  // right: 9.6rem;
  // display: flex;
  justify-content: right;
  background: #ffffff00;
  max-width: initial;
  display: flex;
  // width: 100% !important;
  // margin: 1rem 30%;
  // margin-right: 2rem;

  @media screen and (max-width: $md) {
    // right: 1rem;
    justify-content: right;
    background: #ffffff00;
    max-width: initial;
    display: flex;
    // display: flex;
    // width: 100% !important;
    // margin: 1rem 30%;
    // margin-right: 2rem;
  }

  @media screen and (max-width: $sm) {
    justify-content: right;
    background: #ffffff00;
    max-width: initial;
    display: flex;
    // display: flex;
    // width: 100% !important;
    // margin: 1rem 30%;
    // margin-right: 2rem;
  }
}

.custom-date-range-picker {
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
    cursor: pointer !important;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius: 3px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #cf3aa0 !important;
    border-radius: 3px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #cf3aa0 !important;
    height: 8px !important;
    border-radius: 3px !important;
  }
}
