@import "root/css/variables.scss";

.gridBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  align-items: end;
  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.circleRatingBox {
  display: flex;
  gap: 2px;
  & > * {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $border-color;
  }
  .best {
    background-color: #0fb682;
  }
  .great {
    background-color: #9dba34;
  }
  .good {
    background-color: #fcbc00;
  }
  .middle {
    background-color: #f58025;
  }
  .bad {
    background-color: #eb255d;
  }
}

// --------- SEASONALITY
.titleWithSVG {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 3px;
  padding-bottom: 6px;
  svg {
    width: 16px;
    height: 16px;
  }
}
