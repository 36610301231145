.checkBox {
  padding: 0;
  display: flex;
  gap: 5px;
}

.text {
  color: #43455c;
  white-space: nowrap;
  font-size: 14px;
}
