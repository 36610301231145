@import "root/css/mixins";

.paidContainer {
  position: relative;
  background-color: white;
  padding: 20px;
  margin-top: 24px;
  flex-direction: column;
  display: grid;
  grid-auto-rows: minmax(30px, auto);
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "box2 box2 box2 box2"
    "box1 box3 box4 box4"
    "box6 box6 box6 box5";

  @media screen and (max-width: 1800px) {
    grid-template-areas:
      "box2 box2 box2 box2"
      "box1 box3 box4 box4"
      "box6 box6 box6 box5";
  }

  @media screen and (max-width: 1650px) {
    grid-template-areas:
      "box2 box2 box2 box2"
      "box1 box3 box3 box4"
      "box6 box6 box6 box5";
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1440px) {
    grid-template-areas:
      "box2 box2 box2 box2"
      "box1 box1 box1 box1"
      "box3 box3 box4 box4"
      "box5 box5 box5 box5"
      "box6 box6 box6 box6";
  }

  @media screen and (max-width: 1260px) {
    grid-template-areas:
      "box1 box1 box1 box1"
      "box2 box2 box2 box2"
      "box3 box3 box3 box3"
      "box4 box4 box4 box4"
      "box6 box6 box6 box6"
      "box5 box5 box5 box5";
  }
}

.box1 {
  grid-area: box1;
}

.box1Size {
  min-height: 316px;
}

.box2 {
  grid-area: box2;

  @media screen and (min-width: 1950px) {
    min-width: 70vw;
  }

  @media screen and (max-width: 1950px) {
    min-width: 60vw;
  }

  @media screen and (max-width: 1440px) {
    min-width: unset;
  }
}

.box3 {
  grid-area: box3;
  > div > div:last-child > div {
    @media screen and (max-width: 1800px) {
      justify-content: right;
      gap: 50px;
    }
    @media screen and (max-width: 1440px) {
      justify-content: space-around;
      gap: 0;
    }
    > div:last-child {
      @media screen and (max-width: 1800px) {
        max-width: 140px;
        min-width: 120px;
      }
      @media screen and (max-width: 700px) {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}

.box3Size {
  min-height: 316px;
  min-width: 400px;

  @media screen and (max-width: 530px) {
    min-width: 100%;
  }
}

.box4 {
  grid-area: box4;
}

.box4Size {
  min-height: 316px;
  min-width: 400px;

  @media screen and (max-width: 530px) {
    min-width: 100%;
  }
}

.box5 {
  grid-area: box5;
}

.box5Size {
  min-width: 400px;
  min-height: 100px;

  @media screen and (max-width: 530px) {
    min-width: 100%;
  }
}

.box6 {
  grid-area: box6;
}

.box6Size {
  min-height: 406px;
}

.box7 {
  margin-top: 24px;
  position: relative;
  padding: 16px;
  background-color: white;
}

.noDataMessage {
  position: absolute;
  backdrop-filter: blur(4px);
  background-color: rgba(white, 0.2);
  width: 100%;
  height: 100%;
  padding: 16px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 3px;
  z-index: 12;
  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 35px;
    background-color: rgba(white, 1);
    backdrop-filter: blur(7px);
    width: 70%;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    margin-top: 100px;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 959px) {
      width: 90%;
    }
    @media screen and (max-width: 599px) {
      width: 100%;
      padding: 16px;
    }

    .text {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      color: #43455c;
      line-height: 1.1em;
    }
  }
}

.loader {
  @include loader;
}

.loaderBox {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
}

.loaderFlex {
  div[class*="root"] {
    display: flex;
  }
}
