.cardTwoProcentsContainer{
  display: flex;
  width: 100%;
  background-color: white;
  flex-direction: column;
  gap: 2px;
  padding: 20px 20px 8px 20px;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  h5 {
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    color: #798097
  }
  .data {
    display: flex;
    padding: 5px 0px 0;
    gap: 3px;
    justify-content: space-between;

    .sum{
      font-weight: 500;
      font-size: 18px;
      color: #43455C;

      @media screen and (max-width: 1800px) {
        font-size: 16px;
      }

      @media screen and (max-width: 1600px) {
        font-size: 15px;
      }
    }

    @media screen and (max-width: 1800px) {
      padding: 0 8px;
    }

    @media screen and (max-width: 1600px) {
      padding: 0 4px;
    }
  }
  .resultPercentContainer{
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;

    span {
      font-size: 14px;
    }

    @media screen and (max-width: 1800px) {
      padding: 0 8px;
    }

    @media screen and (max-width: 1600px) {
      padding: 0 4px;
    }
  }
  
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.alignItemsCenter {
  align-items: center;
  display: flex;

  span {
    @media screen and (max-width: 1600px) {
      font-size: 15px;
    }
  }
}
.switch {
  padding: 14px;
}
.switcherBlock {
  display: flex;
  align-items: center;
  > span {
    color: #798097;
    font-size: 12px;
    text-transform: uppercase;
  }
}