.childCardContainer{
    display: flex;
    flex-direction: column;
    padding: 20px 20px 8px 20px;
    margin-top: 10px;
    border-radius: 3px;
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    h5{
        font-weight: 400;
        text-transform: uppercase;
        margin: 0;
        color: #798097;

        //@media screen and (max-width: 1800px) {
        //    font-size: 16px;
        //}
    }

    .sum{
        font-size: 18px;
        font-weight: 500;
        text-align: right;
        display: block;
        color: #43455C;

        @media screen and (max-width: 1800px) {
            font-size: 16px;
        }

        @media screen and (max-width: 1600px) {
            font-size: 15px;
        }
    }

    .data {
        padding-right: 15px;
        gap: 3px;
        justify-content: space-between;

        @media screen and (max-width: 1800px) {
            padding-right: 8px;
        }

        @media screen and (max-width: 1600px) {
            padding-right: 4px;
        }
    }

    .childPercent {
        display: flex;
        justify-content: flex-end;
        padding: 0 15px 5px;
        align-items: center;
        gap: 5px;

        span {
            font-size: 14px;
        }

        @media screen and (max-width: 1800px) {
            padding: 0 8px;
        }

        @media screen and (max-width: 1600px) {
            padding: 0 4px;
        }
    }

    @media screen and (max-width: 1800px) {
        padding: 5px 5px 5px 8px;
    }
}

.alignItemsCenter {
    align-items: center;
    display: flex;
}

.noMarginTop {
    margin-top: 0 !important;
}
