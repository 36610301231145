.diagramDecription {
  background-color: white;
  width: 300px;
  padding: 20px;
  box-shadow: -1px 1px 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  .labelContainer {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;

    span {
      font-weight: bold;
    }
  }

  .legendsBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .hintTitle {
    font-size: 14px;
    color: #43455c;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .legends {
    display: flex;
    gap: 5px;
    align-items: center;

    .circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #00c85a;
    }

    .secondCircle {
      background-color: blue;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }

    .title {
      font-size: 16px;
      color: #43455c;
    }
  }

  .value {
    font-size: 14px;
    color: #798097;
    padding-left: 10px;
  }

  .valuesText {
    font-size: 14px;
    color: #43455c;
    padding-left: 10px;
  }
}
