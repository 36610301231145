@import "root/css/variables.scss";

// --------------------------- ScrollableChartWrapper
.scrollableChartWrapper {
  position: relative;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer !important;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius: 3px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #cf3aa0 !important;
    border-radius: 3px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #cf3aa0 !important;
    border-radius: 3px !important;
  }
  .scrollableChart {
    overflow: auto !important;
  }
}
