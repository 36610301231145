.topProductsTabsBlock {
  height: 100%;

  @media (max-width: 1440px) {
    width: 65vw;
    margin: 0 auto;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0 auto;
  }
}
.hiddenMessage {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 12px;

  span {
    background: rgb(255, 255, 255);
    padding: 6px 12px;
    box-shadow: -1px 1px 8px 4px rgb(0 0 0 / 10%);
    border-radius: 3px;
    font-weight: 500;
    color: #262626;
  }

  @media (min-width: 1440px) {
    width: 483px;
    height: 500px;
  }
}
