@import "root/css/variables.scss";

.root {
	$padding: 8px;
	$width: 44px;
	$height: 24px;
	$thumbSize: 18px;

	position: relative;
	width: $width + ($padding * 2);
	height: $height + ($padding * 2);
	padding: $padding;

	.track {
		background-color: $border-color;
		opacity: 1;
		border-radius: $height;
	}
	.checked + .track {
		background-color: $main-color;
		opacity: 1;
	}

	.switchBase {
		padding: ($height + ($padding * 2) - $thumbSize) * 0.5;

		.thumb {
			width: $thumbSize;
			height: $thumbSize;
			box-shadow: 0px 2px 4px rgba(#00230b, 0.2);
		}
	}

	.checked.switchBase .thumb {
		background-color: white;
	}
}
