@import "root/css/variables.scss";

h2:has(> .button) {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
}
.button {
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}
.close {
  position: absolute;
  right: 14px;
  top: 10px;
  width: 20px;
  height: 20px;
  color: #798097;
  cursor: pointer;
}
div:has(> .modal) {
  max-width: 100%;
}

.modal {
  font-weight: initial;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 24px 16px;
  text-align: left;
  width: 745px;
  .modalHeader {
    display: grid;
    h2 {
      font-size: 24px;
      font-weight: 500;
      padding: 0;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
    .stopWordButton {
      align-items: end;
      justify-content: end;
      padding: 0;
      height: fit-content;
    }
  }

  @media screen and (max-width: $md) {
    width: 100%;
  }
  .modalActions {
    margin-top: 1.5rem;
    width: 100%;

    button {
      width: 100%;
    }
  }
}

// ------------------ VideoPlayerSelector
.containerVideo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.video {
  border: none;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  outline-width: 0;
}
