.wrapper {
  background: #f0f2f5;
  padding-top: 24px;
}

.advertTablesSettings {
  background: white;
  min-height: 58px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 16px;
  gap: 10px;
  flex-wrap: wrap;

  .box {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .line {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-around;
    min-width: 700px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
      min-width: 0;
      width: 100%;
    }
  }

  @media screen and (max-width: 1140px) {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    overflow-x: auto;
  }
}

.customHeight {
  height: 40px;
  max-width: 255px;
  margin: 0px;
}

.textInput {
  width: 100px;
}

.addFilterLink {
  color: #cf3aa0;
  cursor: pointer;
  text-decoration: underline;
  transition: all ease 0.5s;
  align-self: flex-end;
  white-space: nowrap;
  padding-bottom: 10px;
  margin-left: 15px;
  @media screen and (max-width: 600px) {
    align-self: flex-start;
    margin-left: 0;
  }
}

.addFilterLink:hover {
  color: #902870;
}

.filtersBlock {
  display: flex;
  align-items: baseline;
  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
    gap: 10px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.inputWrapper {
  display: flex;
  gap: 10px;
  @media screen and (max-width: 600px) {
  }
}

.marginTop {
  margin-top: 24px;
}

.wrapBox {
  display: flex;
  gap: 15px;
}

.tableWrap {
  position: relative;
  padding: 16px;
}

.disabledButton {
  color: #00000042;
  pointer-events: none;
}
