@import "root/css/variables.scss";

.cicleBox {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 9px;
  width: 100%;
  height: 100%;
  .descriptionCicrleBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 95%;
    gap: 20px;

    @media (max-width: 1024px) {
      min-width: 420px;
    }

    @media (max-width: 480px) {
      min-width: 420px;
      margin: 10px 0;
    }
  }

  .title {
    display: flex;
    align-items: center;
  }

  .title h4 {
    font-size: 18px;
    max-width: 450px;
    color: #43455c;

    @media (max-width: 1920px) {
      max-width: 400px;
    }

    @media (max-width: 1870px) {
      max-width: 375px;
    }

    @media (max-width: 1800px) {
      font-size: 16px;
      max-width: 30vw;
    }

    @media (max-width: 1440px) {
      max-width: 500px;
    }

    @media (max-width: 1200px) {
      max-width: 420px;
    }

    @media (max-width: 1024px) {
      max-width: none;
    }
  }

  .circleContainer {
    display: flex;
    padding: 20px 0;
    justify-content: space-around;

    > div > div,
    > div > div > svg {
      @media (max-width: 1800px) {
        height: 180px !important;
        width: 180px !important;
      }

      @media (max-width: 1200px) {
        height: 160px !important;
        width: 160px !important;
      }
    }

    @media (max-width: 1200px) {
      padding: 25px 0;
    }
  }

  @media screen and (max-width: 1260px) {
    width: 60vw;
    margin: 0 auto;
  }

  @media screen and (max-width: 959px) {
    width: 70vw;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.cicleDescription {
  display: flex;
  flex-direction: column;
  /* grid-gap: 16px; */
  gap: 6px;
  padding-left: 0px;
  max-height: 267px;
  overflow-y: auto;

  @media (max-width: 1800px) {
    max-width: 220px;
    gap: 2px;
  }

  @media (max-width: 1024px) {
    max-width: initial;
    gap: 0;
  }
}

.diagramDecription {
  min-width: 300px;
  padding: 4px 10px;
  border: 1px solid darkgray;
  background-color: white;
  border-radius: 2px;
  display: flex;
  gap: 6px;
  align-items: center;

  .circle {
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }

  span {
    white-space: nowrap;
  }
}

.scrollableBlock {
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;

  .limitedWidth {
    @media (max-width: 2200px) {
      max-width: 500px;
    }

    @media (max-width: 1800px) {
      max-width: none;
    }

    @media (max-width: 1600px) {
      max-width: 500px;
    }

    @media (max-width: 1440px) {
      width: 43vw !important;
      max-width: none;
    }

    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }

  g[class*="recharts-scatter-symbol"] {
    cursor: pointer;
  }
}

.closeIconBlock {
  @media (max-width: 959px) {
    margin-top: 65px;
  }
}

.btnExport {
  text-transform: none;
  background-color: #fff;
  font-weight: 400;
  margin: 0 10px 10px auto;
  width: 250px;
}

.tableWrap {
  margin: 0 10px 10px 10px;
}

.drawerWrap {
  @media (max-width: 930px) {
    div[class*="MuiDrawer-paper"] {
      width: 100%;
      overflow-x: auto;
    }
  }
}
