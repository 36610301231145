.container {
  // background-color: white;
  .title {
    font-size: 18px;
    color: #43455c;
    font-weight: 500;
  }
  .scrollableBlock {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .checkBox {
    cursor: pointer;
  }

  .checkboxContainer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-right: 40px;
    gap: 5px;

    div {
      display: flex;
      align-items: center;
    }
    .trendBox {
      color: #fcbc00 !important;
    }
    .eventBox {
      color: #cf3aa0 !important;
    }

    @media screen and (max-width: 1515px) {
      gap: 0;
    }
    @media screen and (max-width: 959px) {
      justify-content: flex-start;
      padding-right: 0;
    }

    @media screen and (max-width: 599px) {
      flex-direction: column;
      margin: 10px 0;
    }
  }
}

.customLabel {
  outline: 1px solid #cf3aa0;
  border-radius: 5px;
  font-size: 13px;
}

.customLabel:hover {
  background-color: #cf3aa0;
  color: white;
}

.hoveredPoint {
  fill: #cf3aa0;
  cursor: pointer;
}

.hoveredPoint:hover {
  fill: purple !important;
}

.linearLoading {
  position: relative;
}
