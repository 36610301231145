@import "root/css/variables.scss";

.link {
  color: #cf3aa0 !important;
  text-decoration: underline;
}

.link:hover {
  text-decoration: none;
}

.root {
  position: relative;
  // margin-left: $drawer-width;
  margin-left: 0;
  transition: $transition;

  &[data-drawer-open="false"] {
    // margin-left: $drawer-minimized-width;
    margin-left: 0;

    @media screen and (max-width: $md) {
      margin-left: 0;
    }
  }

  @media screen and (max-width: $md) {
    margin-left: 0;
  }

  .content {
    position: relative;
    // width: 100%;
    min-height: 100vh;
    overflow: hidden;
    padding: 10px;
    margin-left: $drawer-minimized-width;

    @media screen and (max-width: $md) {
      padding: (20px + $header-height) 10px 20px 10px;
    }
  }

  &[data-drawer-open="false"] {
    @media screen and (max-width: $md) {
      .content {
        margin: 0;
      }
    }
  }
}

// ----------------------
.menuPageTitleBox {
  display: flex;
  align-items: center;
  gap: 4px;
  .betaMini,
  .newMini {
    padding: 2px 3px;
    font-size: 10px;
    color: white;
    border-radius: 5px;
  }
}
.betaBanner,
.newBanner {
  padding: 6px 8px 7px 8px;
  font-size: 18px;
  line-height: 18px;
  background-color: $main-color;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
}
.betaBanner,
.betaMini {
  background-color: $main-color;
}
.newBanner,
.newMini {
  background-color: $success-color;
}
