@import "root/css/variables.scss";

.root {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 300px;

  .image {
    flex: none;
    margin-right: 10px;
  }

  .text {
    min-width: 100px;
    color: $main-color;
    cursor: pointer;
    margin-right: auto;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      text-decoration: underline;
    }
  }

  .modal {
    position: absolute;

    // left: 30px;
    // bottom: -106px;
    // bottom: 60px;
    img {
      position: absolute;
      bottom: 15px;
      right: -64px;
      width: auto;
      background: white;
      z-index: 100000000;
      border: 2px solid $main-color;
      border-radius: 0px;
      transition: 0.3s;
    }

    bottom: -48px;
    right: -191px;
    width: auto;
    background: white;
    z-index: 11000;
    border: 2px solid $main-color;
    border-radius: 0px;
    transition: 0.3s;
  }

  .modalNone {
    display: none;
  }

  .text_black {
    color: $eerie-black-color;
    min-width: 100px;
    margin-right: auto;
  }

  .wbLink {
    position: relative;
    width: 22px;
    height: 22px;
    flex: none;
    background-color: $main-color;
    border-radius: 100%;
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 10px;

    .icon {
      position: relative;
      width: 16px;
      height: 16px;
      color: white;
    }
  }
}

.modalImage {
  width: 250px;
  max-height: 340px !important;
  height: max-content;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
}

@media (max-height: 800px) {
  .modalImage {
    width: 180px;
    max-height: 340px !important;
  }
}

@media (max-height: 600px) {
  .modalImage {
    width: 140px;
    max-height: 340px !important;
  }
}

@media (max-height: 480px) {
  .modalImage {
    width: 120px;
    max-height: 340px !important;
  }
}

@media (max-height: 400px) {
  .modalImage {
    width: 100px;
    max-height: 340px !important;
  }
}

@media (max-height: 360px) {
  .modalImage {
    width: 80px;
    max-height: 340px !important;
  }
}

.productButtonsBox {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
}
.productInNewTabIcon {
  // margin-left: 10px;
  color: $main-color;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
}

.disabled {
  color: $faded-color;
  cursor: initial;
}
