.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 10px 0 10px;
  // background-color: white;
  border-radius: 0px;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  h4 {
    font-size: 18px;
    margin: 0;

    @media screen and (max-width: 1800px) {
      font-size: 16px;
    }
  }

  svg {
    overflow: visible;
  }

  @media (max-width: 1440px) {
    //width: 60vw;
    margin: 0 auto;
  }

  @media (max-width: 959px) {
    width: 70vw;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.promotionContainer {
  box-shadow: none !important;
}

.finesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  h4 {
    font-size: 18px;
    margin: 0;
    color: #43455c;

    @media screen and (max-width: 1800px) {
      font-size: 16px;
    }
  }

  svg {
    overflow: visible;
  }
}

.diagramDecription {
  background-color: white;
  padding: 10px;
  border: 1px solid;
}

.positionEnd {
  display: flex;
  justify-content: flex-end;
  min-width: 1300px;
}

.positionCenter {
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: center;
  width: 100%;
  max-width: 520px;

  > div {
    @media screen and (max-width: 1600px) {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  > div,
  > div svg {
    @media (max-width: 1440px) {
    }
  }

  @media (max-width: 1800px) {
    align-items: start;
  }

  @media (max-width: 1600px) {
    justify-content: space-between;
  }

  @media (max-width: 1440px) {
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: 600px) {
    margin: 10px 0;
    height: 450px;
  }
}

.scrollableBlock {
  overflow: auto;
}

.commissionScrollableBlock {
  overflow: auto;
  height: 100%;

  @media (max-width: 1800px) {
    height: 550px;
  }
}

.scrollableBlock.promotionHeight {
  @media (max-width: 1800px) {
    height: 100%;
  }
}

.customScrollableHeight {
  max-height: 400px;
  overflow-y: auto;

  div[class*="recharts-wrapper"] {
    height: 440px !important;
  }

  @media (max-width: 1800px) {
    max-height: 712px;
    height: 712px !important;
  }

  @media (max-width: 1600px) {
    max-height: 560px;
    height: 560px !important;
  }
}

.promotionHeight {
  max-height: 615px;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 1750px) {
    max-height: 780px;
    height: 780px !important;
  }

  @media (max-width: 1440px) {
    max-height: 550px;
    height: 550px !important;
  }
}

.finesScrollableBlock {
  overflow: auto;
  height: 100%;
}

.promotionAcceptance {
  height: 400px;

  @media (max-width: 1440px) {
    height: 550px;
  }
}

.isLink {
  cursor: pointer;
  transition: all ease 0.5s;

  a {
    max-width: 200px;
  }
}

.isLink:hover {
  fill: #cf3aa0;
}

.diagramInfoDecription {
  background-color: white;
  padding: 6px;
  z-index: 9;
  overflow-y: auto;
  max-width: 50vw;
  min-width: 280px;
  position: relative;
}

.diagramInfoDecription .title {
  max-width: 500px;
  font-size: 18px;
  margin: 5px 5px 15px;
  font-weight: 500;

  @media (max-width: 1800px) {
    font-size: 16px;
  }
}

.diagramInfoDecription img {
  width: 60px;
  height: auto;
  min-height: 60px;
  object-fit: contain;
  background: #a9a9a952;
  border: 1px solid #a9a9a952;

  @media (max-width: 959px) {
    width: 40%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.diagramInfoDecription .itemBlock {
  margin-bottom: 10px;
  padding: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  > span {
    @media (max-width: 959px) {
      width: 60%;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding-left: 0;
    }
  }
}

.closeIconBlock {
  @media (max-width: 959px) {
    margin-top: 65px;
  }
}

.onHover:hover {
  opacity: 0.5;
}

.alignedTitle {
  display: flex;
  align-items: center;
}

.tick:hover {
  color: blue;
  fill: blue;
}

.maxWidth {
  max-width: 450px;

  @media (max-width: 1440px) {
    max-width: none;
  }
}

.btnBlock {
  position: sticky;
  background-color: #fff;
  right: 10px;
  top: -6px;
}

.exportBtn {
  margin: 0 0 0 auto;
}

.margin {
  margin-top: 60px !important;
}

.infoImage {
  height: 80px !important;
  background: #f1f2f2 !important;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.itemBlock {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 8px 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.textItem {
  margin-bottom: 5px;
  font-size: 16px;
  max-width: 400px;
  color: #798097;
}

.bold {
  font-weight: 500;
  color: #000;
}
