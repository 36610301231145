@import "root/css/variables.scss";

.header {
	color: $gun-powder-color;
	margin-bottom: 4px;
	font-weight: 500;
}
.value {
	color: $faded-color;
	font-weight: 400;
}
.tooltip {
	background-color: white;
	font-size: 14px;
	box-shadow: 0 4px 10px $box-shadow-color;
	padding: 10px 14px;
	text-align: center;
}
.tooltipArrow {
	color: white;
}
.tooltipConent {
	width: 5px;
	height: 5px;
}
