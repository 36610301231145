.hiddenMessage {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 12px;

  p {
    padding: 6px 12px;
    font-weight: 500;
    color: #262626;
  }
  .boxMessage {
    // border: solid 3px #cf3aa0;
    padding: 30px;
    border-radius: 3px;
    box-shadow: -1px 1px 8px 4px rgb(0 0 0 / 10%);
    background: rgb(255, 255, 255);
  }
}
