@import "root/css/variables";
@import "root/css/mixins";

.hint {
  cursor: pointer;
}

.root {
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: 10;
    top: 0;
    width: 15px;
    left: -15px;
    height: 100%;
    transition: box-shadow 0.3s ease-out;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 10;
    top: 10px;
    width: 15px;
    right: -15px;
    height: 100%;
    transition: box-shadow 0.4s ease-out;
  }

  &[data-shadows="left"]:before,
  &[data-shadows="both"]:before {
    box-shadow: 8px 0 8px #00000045;
  }

  &[data-shadows="right"]:after,
  &[data-shadows="both"]:after {
    box-shadow: -8px 0 14px #00000045;
  }

  &[data-shadows="hidden"]:before,
  &[data-shadows="hidden"]:after {
    box-shadow: none;
  }

  .loading {
    z-index: 15;
  }

  .container {
    -webkit-overflow-scrolling: touch;

    [data-sticky="true"] {
      position: sticky !important;
      z-index: 9 !important;
      left: 0 !important;
      right: 0 !important;
      border-left: 1px dashed rgba(224, 224, 224, 1);
      border-right: 1px dashed rgba(224, 224, 224, 1);

      @media screen and (max-width: 600px) {
        left: auto !important;
        right: auto !important;
      }
    }

    thead {
      th {
        background: $background-color-total;
      }

      .cell {
        color: $faded-color;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.1em;
        user-select: none;
        left: unset;

        &[data-hassorder="true"] {
          cursor: pointer;
        }

        div {
          width: inherit;
          min-width: inherit;
        }

        .cellFlex {
          position: relative;
          display: flex;
          align-items: center;
          min-height: 28px;

          .sorter {
            position: relative;
            display: inline-block;
            height: 100%;
            flex: none;

            svg {
              position: absolute;
              color: rgba($faded-color, 0.3);
              transform: translateY(-50%);

              &[data-active="true"] {
                color: $main-color;
              }

              &:nth-of-type(1) {
                top: calc(50% - 4px);
              }

              &:nth-of-type(2) {
                top: calc(50% + 4px);
              }
            }
          }
        }
      }
    }

    tbody {
      .childrenRow {
        background-color: white;
      }

      .cell {
        position: relative;
        color: $eerie-black-color;
        font-size: 14px;
        line-height: 1.2em;
        background-color: white;
        white-space: nowrap;
      }

      .noDataText {
        color: $faded-color;
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    box-shadow: 0 -1px 0 0 $pagination-border-shadow-color;
    // justify-content: flex-end;

    .perPage {
      display: flex;
      align-items: center;
      margin-right: auto;
    }
  }
}

.flexWrapNowrap {
  flex-wrap: nowrap;
}

.cellFlexInRow {
  display: flex;
  align-items: center;

  .sorter {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 100%;
    flex: none;

    svg {
      position: absolute;
      color: rgba($faded-color, 0.3);
      transform: translateY(-50%);

      &[data-active="true"] {
        color: $main-color;
      }

      &:nth-of-type(1) {
        top: calc(50% - 4px);
      }

      &:nth-of-type(2) {
        top: calc(50% + 4px);
      }
    }
  }
}

.commonRow {
  position: sticky;
  z-index: 1000;
  top: 89px;

  td {
    background-color: #fff;
  }
}

.cellWrap {
  background-color: #fff;
}

.loader {
  @include loader;
}
