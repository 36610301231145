.checkboxFilter{
    background-color: white;
    display: flex;
    align-items: center;

    label {
        height: 48px;
    }
    span{
        font-size: 13px;
    }

    > div {
        @media screen and (max-width: 1920px) {
            max-width: 1155px;
        }

        @media screen and (max-width: 1800px) {
            max-width: 960px;
        }

        @media screen and (max-width: 1600px) {
            max-width: 795px;
        }

        @media screen and (max-width: 1440px) {
            max-width: 100%;
        }
    }
}

.stackCheckbox{
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        gap: 16px;
        label{
            margin: 0!important;
        }
    }
}
