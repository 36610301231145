@import "root/css/variables.scss";

.drawer {
  max-width: 1000px;
}
.root {
  color: #fff;
  background: $main-color;
  padding: 16px;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  margin-left: 0;
  @media (max-width: $md) {
    & {
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  .more:hover {
    text-decoration: initial;
  }

  .more {
    font-size: 18px;
    text-decoration: underline;
  }
}
.orderDemonstrationBox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // margin-top: 0.4rem;
  margin: 0.4rem 0;
  @media (max-width: $md) {
    & {
      margin-left: 0;
    }
  }
  .orderDemonstration {
    // color: $secondary-text-color;
    // color: $main-color;
    font-weight: 900;
    cursor: initial;
    font-size: 1rem;
  }
}
.asButtonBox {
  height: 50px;
  // border: 1px solid lightgreen;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: green;
  font-weight: 700;
  font-size: 1.3rem;
}

.partnerLogoBox {
  width: 80px;
  height: 80px;
  img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
}

.expandArrow {
  transition: 0.2s;
  width: 35px;
  height: 35px;
  color: $border-color;
  transform: rotate(-90deg);
}
.expandArrowOpenedBox {
  .expandArrow {
    color: $main-color;
    transform: rotate(0deg);
  }
  color: $main-color;
}
.activeRadioLabel {
  color: $main-color;
  font-weight: 600;
}

.advantagesRow {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 0.5rem;
  align-items: center;
}

.cards {
  display: flex;
  gap: 1rem;
  @media (max-width: $sm) {
    display: grid;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    cursor: pointer;
    position: relative;
    flex: 1;
    // color: $main-color;
    border: 1px solid $main-color;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    border-radius: 4px;

    .icon {
      svg {
        font-size: 50px;
      }
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #565870;
    }
  }
}

.item {
  position: relative;
  user-select: none;
  position: absolute;
  display: flex;
  right: 0;
  top: 8px;

  .flexBox {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    // min-height: 70px;

    .itemContent {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      flex-grow: 1;

      .nameWrapper {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        &[data-active="true"] {
          .radio {
            border: 5px solid $main-color;
          }

          .name {
            // color: $gun-powder-color;
            color: #000;
          }
        }

        .radio {
          position: relative;
          width: 26px;
          height: 26px;
          margin-right: 8px;
          flex: none;
          border-radius: 50%;
          transition: border 0.06s ease-out;
          border: 1px solid #798097;
        }

        .discount {
          background: #387e33;
          font-weight: 700;
          display: flex;
          grid-gap: 5px;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: 0.02em;
          color: #fff;
          padding: 5px 15px;

          @media screen and (max-width: $md) {
            padding: 2px 10px;
          }

          border-radius: 30px;
          // background: #417e2d;
          // margin: 0 0 0 10px;
        }

        .name {
          // color: $faded-color;
          color: #000;
          margin-right: 8px;
          font-size: 18px;
          font-weight: normal;
        }
      }

      .input {
        position: relative;
        width: 100%;
        height: 50px;
        border-radius: 3px;
        background-color: white;
        color: $gun-powder-color;
        padding: 16px 15px;
        font-size: 16px;
        font-family: $font-family;
        border: 1px solid $border-color;
        margin-right: 10px;
        outline: none;

        &::placeholder {
          font-family: $font-family;
        }
      }
    }

    .buttons {
      position: relative;
      flex: none;

      & > *:first-child {
        margin-right: 10px;
      }

      .green {
        color: $success-color;
      }

      .red {
        color: $main-color;
      }
    }
  }
}

.sendSupportsChatLink {
  color: $main-color !important;
  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
}
