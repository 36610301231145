.legendsBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // width: 240px;
  margin-bottom: 10px;

  .legends {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .title {
    font-size: 16px;
    color: #798097;
  }
  .value {
    font-size: 16px;
    color: #000;
  }

  .img {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 255px !important;
    height: 255px !important;
    margin: 0 auto;
  }
  .bold {
    font-weight: 500;
    color: #000;
  }
}
