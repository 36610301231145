@import "root/css/variables.scss";

.root {
  position: relative;
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 3px;
  padding: 0;
  outline: none;
  transition: $transition;

  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }

  &[data-variant="primaty-outlined-hover"] {
    border: 1px solid $border-color;
    color: $faded-color;
  }
  &[data-variant="primaty-outlined-hover"]:not([disabled]):hover {
    border: 1px solid $main-color;
    color: $main-color;
    background-color: transparent;
  }

  &[data-variant="default-outlined"] {
    border: 1px solid $border-color;
    color: $faded-color;
  }

  &[data-variant="primaty-outlined"] {
    border: 1px solid $main-color;
    color: $main-color;
  }
  &[data-variant="primaty-outlined"][disabled] {
    opacity: 0.4;
  }
  &[data-variant="filled"] {
    background-color: $main-color;
    color: white;
  }
  &[data-variant="filled"]:not([disabled]):hover {
    background-color: $background-hover-color;
  }

  &[data-variant="outlined-shadow"] {
    border: 1px solid $border-color;
    color: $main-color;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
  &[data-variant="outlined-shadow"][disabled] {
    opacity: 0.4;
  }

  .preloader {
    color: inherit;
  }
  .icon {
    color: inherit;
  }
}
