@import "root/css/variables.scss";

.root {
	z-index: 1000 !important;

	.paper {
		.close {
			position: absolute;
			top: 6px;
			right: 6px;
			width: 36px;
			height: 36px;
			padding: 5px;
			color: #798097;
			cursor: pointer;
			flex: none;
			z-index: 9;

			svg {
				width: 26px;
				height: 26px;
			}
		}

		margin: 16px;
	}
}
