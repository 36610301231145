.promotionMediaHeader {
  @media screen and (max-width: 1440px) {
    justify-content: flex-end;
    display: flex;
  }
}

.customHeight {
  height: 40px;
  max-width: 360px;
  width: 100%;

    @media screen and (max-width: 600px) {
        width: 100%;
        max-width: 100%;
    }
}

.btn {
  width: 100%;
}

.relative {
  position: relative;
}

.selectContainer {
    //margin: 8px;
  width: 100%;
    @media screen and (max-width: 1440px) {
        display: flex;
    }
}

.menuPaper {
  max-height: 300px;
  overflow: auto;
  position: relative;

  @media screen and (max-width: 599px) {
    max-width: 320px;
  }
}

.fixedApplyButton {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: white;
}

.fixedApplyButton:hover {
  background: white;
}
