#select-month.MuiInputLabel-shrink {
	left: auto;
	right: auto;
}

.base-Popper-root.MuiAutocomplete-popper {
	padding-bottom: 8px;
}
.fixed-top{
	position: fixed;
	display: block;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	padding-left: 72px !important;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
	transition: width 0.3s cubic-bezier(0.25, 0.8, 0.05, 1), margin-left 0.3s cubic-bezier(0.25, 0.8, 0.05, 1);
	@media screen and (max-width: 950px) {
		top: 64px;
		padding-left: 0;
	}
}