@import "root/css/mixins";

.returnsRides {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  padding: 20px;
  margin-top: 24px;

  .content {
    display: grid;
    grid-auto-rows: minmax(30px, auto);
    grid-gap: 10px;
    margin-bottom: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "box5 box5 box5 box5"
      "box1 box2 box6 box6"
      "box1 box2 box6 box6"
      "box1 box2 box6 box6"
      "box3 box4 box6 box6"
      "box3 box4 box6 box6"
      "box3 box4 box6 box6"
      "box8 box8 box8 box7"
      "box8 box8 box8 box7";

    @media screen and (max-width: 1800px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "box5 box5 box5 box5 box5 box5"
        "box1 box2 box6 box6 box6 box6"
        "box3 box4 box6 box6 box6 box6"
        "box8 box8 box8 box8 box7 box7"
        "box8 box8 box8 box8 box7 box7";
    }

    @media screen and (max-width: 1600px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "box5 box5 box5 box5"
        "box1 box2 box6 box6"
        "box3 box4 box6 box6"
        "box8 box8 box7 box7"
        "box8 box8 box7 box7";
    }

    @media screen and (max-width: 1460px) {
      grid-template-areas:
        "box5 box5 box5 box5"
        "box1 box1 box2 box2"
        "box3 box3 box4 box4"
        "box6 box6 box7 box7"
        "box8 box8 box8 box8";
    }

    @media screen and (max-width: 1124px) {
      grid-template-areas:
        "box5 box5 box5 box5"
        "box1 box1 box2 box2"
        "box3 box3 box4 box4"
        "box6 box6 box6 box6"
        "box7 box7 box7 box7"
        "box8 box8 box8 box8";
    }

    @media screen and (max-width: 600px) {
      grid-template-areas:
        "box5 box5 box5 box5"
        "box1 box1 box1 box1"
        "box2 box2 box2 box2"
        "box3 box3 box3 box3"
        "box4 box4 box4 box4"
        "box6 box6 box6 box6"
        "box8 box8 box8 box8"
        "box7 box7 box7 box7";
    }
  }

  .box6 {
    height: 100%;
    > div > div:last-child > div {
      @media screen and (max-width: 1200px) {
        justify-content: right;
        gap: 50px;
      }
      @media screen and (max-width: 1124px) {
        justify-content: space-around;
        gap: 0;
      }
      > div:last-child {
        @media screen and (max-width: 1200px) {
          max-width: 140px;
          min-width: 120px;
        }
        @media screen and (max-width: 700px) {
          max-width: 100%;
          min-width: 100%;
        }
      }
    }
  }

  .box1 {
    grid-area: box1;
  }

  .box1Size {
    min-height: 216px;
  }

  .box2 {
    grid-area: box2;
  }

  .box2Size {
    min-height: 216px;
    min-width: 300px;

    @media screen and (max-width: 360px) {
      min-width: 100%;
    }
  }

  .box3 {
    grid-area: box3;
  }

  .box3Size {
    min-height: 140px;
  }

  .box4 {
    grid-area: box4;
  }

  .box4Size {
    min-height: 140px;
  }

  .box5 {
    grid-area: box5;

    button {
      @media screen and (max-width: 960px) {
        font-size: 15px;
      }
    }

    > div {
      @media screen and (max-width: 1024px) {
        width: 60vw;
        margin: 0 auto;
      }

      @media screen and (max-width: 959px) {
        width: 70vw;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1800px) {
      min-width: 38vw;
    }

    @media screen and (max-width: 1600px) {
      min-width: 35vw;
    }
  }

  .box6 {
    grid-area: box6;
  }

  .box6Size {
    min-height: 312px;
  }

  .box7 {
    grid-area: box7;

    width: 100%;

    > div {
      @media screen and (max-width: 1024px) {
        width: 60vw !important;
        margin: 0 auto;
      }

      @media screen and (max-width: 959px) {
        width: 70vw !important;
      }

      @media screen and (max-width: 767px) {
        width: 100% !important;
      }
    }
  }

  .box8 {
    grid-area: box8;
  }

  .box8Size {
    min-height: 380px;
  }
}

.noDataMessage {
  position: absolute;
  backdrop-filter: blur(4px);
  background-color: rgba(white, 0.2);
  width: 100%;
  height: 100%;
  padding: 16px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 3px;
  z-index: 12;
  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 35px;
    background-color: rgba(white, 1);
    backdrop-filter: blur(7px);
    width: 70%;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    margin-top: 100px;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 959px) {
      width: 90%;
    }
    @media screen and (max-width: 599px) {
      width: 100%;
      padding: 16px;
    }

    .text {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      color: #43455c;
      line-height: 1.1em;
    }
  }
}

@media (max-width: 1440px) {
  .content {
    gap: 10px !important;
  }
}

.loader {
  @include loader;
}

.boxLoader {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  min-height: 100px;
}

.tableWrap {
  position: relative;
}
