.cardTwoTitleContainer{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 2px 10px 0px #0000001A;

    height: 100%;
    min-width: 300px;

    .titleContainer{
        display: flex;
        align-items: center;
        h4{
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            color: #798097;
            margin: 0;
        }
    }
    .commisionValueContainer{
        display: flex;
        justify-content: flex-end;
        .sum{
            font-size: 18px;
            font-weight: 500;

            @media(max-width: 1800px) {
                font-size: 16px;
            }
        }
    }
    .summaryContainer{
        padding-left: 15px;
    }
    .precentWithIconContainer{
        display: flex;
        gap: 8px;
        justify-content: flex-end;
        align-items: center;
    }

    @media(max-width: 1800px) {
        min-width: 260px;
    }

    @media(max-width: 1600px) {
        min-width: 220px;
    }
}

.widthTrip {
    display: flex;
    align-items: center;
}
