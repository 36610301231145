.promotionMediaHeader {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px;

  .switcherBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .checkboxContainer {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  @media screen and (max-width: 1440px) {
    flex-direction: column;
    height: auto;
    align-items: initial;
  }
}

.statusesBox {
  width: 100%;
  display: flex;
  @media screen and (max-width: 1440px) {
    //justify-content: flex-end;
    display: flex;
  }
}

.customHeight {
  height: 40px;
  max-width: 360px;
  width: 100%;

  @media screen and (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }
}
