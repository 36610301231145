@import "root/css/variables.scss";

.product {

    .image {
        flex: none;
        margin-right: 10px;
    }
}

.listHeader {
    .h4 {
        border-bottom: 2px dashed $faded-color;
        display: inline-block;
        word-break: break-word;

        @media screen and (max-width: $md) {
            font-size: 18px;
        }
    }

    .h4Input {
        color: $gun-powder-color;
        font-size: 24px;
        font-family: $font-family;
        font-weight: 500;
        line-height: 1.235;
        letter-spacing: 0.00735em;
        padding: 0;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px dashed $main-color;
        border-radius: 0;
        width: calc(100% - 28px);

        @media screen and (max-width: $md) {
            font-size: 18px;
        }

        &::placeholder {
            color: $faded-color;
            font-size: 24px;
            font-family: $font-family;
            font-weight: 500;
            line-height: 1.235;
            letter-spacing: 0.00735em;

            @media screen and (max-width: $md) {
                font-size: 18px;
            }
        }
    }

    .editIcon {
        position: relative;
        width: 20px;
        height: 20px;
        flex: none;
        margin-left: 10px;
        color: $faded-color;
        cursor: pointer;
        margin: 4px;
    }
}

.listActions {
    .listActionsGrid {
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            justify-content: start;

            @media screen and (max-width: 450px) {
                flex-direction: column-reverse;
            }
        }
    }

    .btns {
        background-color: transparent;
        border: 1px solid $main-color;
        color: $main-color;
    }

    .btns:hover {
        color: white;
        background-color: $main-color;
    }

    .btnSettings {
        border: 1px solid $main-color;
        color: $main-color;
    }

    .btnSettings:hover {
        background-color: transparent;
    }

    .dateBox {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 768px) {
        .rightBtns {
            .fwButton {
                max-width: 200px;
                width: 180px;
                min-width: 180px;
            }

            @media screen and (max-width: 360px) {
                .fwButton {
                    width: 180px;
                }
            }
        }

        .rightBtns div {
            width: min-content;
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: space-around;
        flex-wrap: inherit;

        .leftBtns {
            width: calc(100% + 40px);
        }

        @media screen and (max-width: 450px) {
            width: 50%;

            .leftBtns {
                width: calc(100% + 70px);
            }
        }
    }
}

.labelText {
  svg {
    width: 20px;
    height: 20px;
    color: $main-color;
  }
}
