@import "root/css/variables.scss";

.root {
	background-color: white;
	color: $tooltip-color;
	font-size: 14px;
	font-weight: 300;
	box-shadow: 0 3px 8px $breadCrumb-text-color;
	//box-shadow: 0 3px 7px rgb(0 0 0 / 44%);
	padding: 14px 18px;
	text-align: center;
}

.root.dark {
	color: white;
	background-color: $drawer-background-color;
}

.arrow {
	color: white;
}

.arrow.dark {
	color: $main-color;
}