.penaltyDynamicContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 3px;

  .fullBox {
    //display: flex;
    //justify-content: space-between;
  }

  .containerCheckbox{
    overflow-y: auto;
    height: 270px;

    .fineName {
      max-width: 440px;

      @media screen and (max-width: 1600px) {
        font-size: 15px;
      }
    }

    .checkBoxBlock{

      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
      padding: 6px 6px 6px 0;
      margin-bottom: 5px;

      > div {
        display: flex;
        align-items: flex-start;
      }
    }

    .checkBox{
      padding: 1px 10px 3px 0;
      //width: 80px;
    }

    @media screen and (max-width: 580px) {
      height: auto;
      padding-bottom: 20px;
      max-height: 275px;
    }
  }

  h4 {
    font-size: 18px;
    color: #43455C;
    margin-bottom: 20px;

    @media screen and (max-width: 1800px) {
      font-size: 16px;
    }
  }
}

.diagramDecription {
  background-color: white;
  padding: 10px;
  border:1px solid;
}


.showDate{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
