@import "root/css/variables.scss";

// ---------------------- ProductDrawerContent
.colorCheckboxWrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .checkbox {
    color: $main-color;
    font-weight: 500;
    font-size: 16px;
    margin-left: 2rem;
    cursor: pointer;
    text-decoration-line: underline;
  }

  @media screen and (max-width: $sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  & > * {
    margin-right: 36px;
    margin-bottom: 10px;
  }
}

.socialNetworksCheckbox {
  padding: 0;
}

.titleBox {
  // margin-bottom: 20px;
  .title {
    font-weight: 600;

    .subTitle {
      font-weight: bold;
      color: $main-color;
      padding: 0 5px;
    }
  }
}

.graphFontStyle {
  font-size: 12px;
  color: $faded-color;

  text {
    fill: $faded-color;
  }
}

.tooltip {
  background: white;
  box-shadow: -1px 7px 20px rgba(black, 0.05);
  border-radius: 3px;
  padding: 20px;
  overflow-y: auto;
  pointer-events: auto;

  // max-height: 280px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightgrey;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb-background-color;
  }

  .date {
    font-size: 12px;
    color: $gun-powder-color;
    margin-bottom: 10px;
  }

  .colorInTooltip {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: -10px;
    }
  }

  .percentInTooltip {
    margin-left: 10px;
  }

  .tooltipTitle {
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: $gun-powder-color;
  }

  .tooltipQuantity {
    font-family: Roboto;
    font-size: 12px;
    text-align: center;
    color: $faded-color;
  }
}

.dateMonth {
  text-transform: capitalize;
}

// TABLES
.scrollableTableBox {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer !important;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cf3aa0;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #cf3aa0;
    border-radius: 3px;
  }

  div:has(> table) {
    max-height: 800px !important;
  }
}

//
.colorCheckboxWrapper {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: $sm) {
    flex-direction: column;
  }

  & > * {
    margin-right: 36px;
    margin-bottom: 10px;
  }
}
