@import "root/css/variables.scss";

.root {
  height: 50px;
  padding: 0 22px;
  font-weight: 400;
  font-size: 16px;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
  border-radius: 3px;
  outline: none;
  transition: $transition;
  text-transform: none;

  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }

  &[data-fullwidth="true"] {
    width: 100%;
  }

  &[data-loading="true"] {
    pointer-events: none;

    .preloader {
      opacity: 1;
    }

    .iconBefore,
    .iconAfter,
    .label {
      opacity: 0;
    }
  }

  &[data-variant="filled"] {
    background-color: $main-color;
    color: white;
  }

  &[data-variant="filled"]:not([disabled]):hover {
    background-color: $background-hover-color;
  }

  &[data-variant="filled"]:not([disabled]):active {
    background-color: $main-color;
  }

  &[data-variant="outlined"] {
    background-color: transparent;
    color: $main-color;
    box-shadow: inset 0 0 0 1px $main-color;
  }

  &[data-variant="outlined"]:not([disabled]):hover {
    box-shadow: inset 0 0 0 1px $background-hover-color;
    color: $background-hover-color;
  }

  &[data-variant="outlined"]:not([disabled]):active {
    box-shadow: inset 0 0 0 1px $main-color;
    color: $main-color;
  }

  &[data-variant="outlined-white-sign"] {
    padding: 0 30px;
    height: 42px;
    background-color: transparent;
    color: white;
    box-shadow: inset 0 0 0 1px white;
  }

  &[data-variant="outlined-white-border"] {
    padding: 0 30px;
    height: 42px;
    color: rgba(121, 128, 151, 1) !important;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px rgb(192, 191, 191);
  }

  &[data-variant="filled-sign"] {
    padding: 0 30px;
    height: 42px;
    background-color: $main-color;
    color: white;
  }

  &[data-variant="filled-sign"]:not([disabled]):hover {
    background-color: $background-hover-color;
  }

  &[data-variant="filled-sign"]:not([disabled]):active {
    background-color: $main-color;
  }

  &[data-variant="empty-red"] {
    background-color: transparent;
    color: $faded-color;
    box-shadow: none;
  }

  &[data-variant="empty-red"]:not([disabled]):hover {
    color: $data-negative;
  }

  &[data-variant="empty-red"]:not([disabled]):active {
    color: #c51037;
  }

  &[data-variant="filled-red"] {
    background-color: #eb255d;
    color: white;
  }

  &[data-variant="filled-red"]:not([disabled]):hover {
    background-color: #eb255dbe;
  }

  &[data-variant="filled-red"]:not([disabled]):active {
    background-color: #eb255d;
  }

  &[data-variant="filled-red"] {
    background-color: #eb255d;
    color: white;
  }

  &[data-variant="filled-red"]:not([disabled]):hover {
    background-color: #eb255dbe;
  }

  &[data-variant="filled-red"]:not([disabled]):active {
    background-color: #eb255d;
  }

  &[data-variant="filled-green"] {
    background-color: #68b818;
    color: white;
  }

  &[data-variant="filled-green"]:not([disabled]):hover {
    background-color: #68b818be;
  }

  &[data-variant="filled-green"]:not([disabled]):active {
    background-color: #68b818;
  }

  &[data-variant="filled-green"] {
    background-color: #68b818;
    color: white;
  }

  &[data-variant="filled-green"]:not([disabled]):hover {
    background-color: #68b818be;
  }

  &[data-variant="filled-green"]:not([disabled]):active {
    background-color: #68b818;
  }

  //////
  &[data-variant="filled-teal"] {
    background-color: #0fb682;
    color: white;
  }

  &[data-variant="filled-teal"]:not([disabled]):hover {
    background-color: #0fb682be;
  }

  &[data-variant="filled-teal"]:not([disabled]):active {
    background-color: #0fb682;
  }

  &[data-variant="filled-teal"] {
    background-color: #0fb682;
    color: white;
  }

  ////
  &[data-variant="filled-pine"] {
    background-color: #238e43;
    color: white;
  }

  &[data-variant="filled-pine"]:not([disabled]):hover {
    background-color: #238e43be;
  }

  &[data-variant="filled-pine"]:not([disabled]):active {
    background-color: #238e43;
  }

  &[data-variant="filled-pine"] {
    background-color: #238e43;
    color: white;
  }
  ///

  @mixin icon {
    position: relative;
    font-size: inherit;
    width: 1.2em;
    height: 1.2em;
    flex: none;
    color: inherit;
  }

  .iconBefore {
    @include icon;
    margin-right: 6px;
  }

  .iconAfter {
    @include icon;
    margin-left: 6px;
  }

  .preloader {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: inherit;
    transition: $transition;
    opacity: 0;
  }

  .label {
    color: inherit;

    &[data-pulse="true"] {
      animation: pulse 2s ease-in-out infinite;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
