@import "root/css/variables.scss";

.root {
	border-bottom: solid 1px $border-color;
	align-items: center;
	gap: 10px;
}
.indicator {
	background-color: $main-color;
	height: 3px;
}
.disabled {
	opacity: 0.4 !important;
}

.customNoBorder {
	border-bottom: none !important;
}
.scrollButtonRoot{
	background-color: #CF3AA0;
	border-radius: 50%;
	height: 24px;
	width: 24px;
	color: white;
	opacity: 1;
}