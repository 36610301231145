.childCardContainer{
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 2px;
    margin-top: 12px;
    border-radius: 3px;
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    h4{
		font-size: 14px;
        font-weight: 400;
        color: #798097;
        text-transform: uppercase;
        margin: 0 0 8px;
    }

    .sum{
        font-size: 18px;
        font-weight: 500;
        text-align: right;
        display: block;
        color: #43455C;

        @media screen and (max-width: 1800px) {
            font-size: 16px;
        }

        @media screen and (max-width: 1600px) {
            font-size: 15px;
        }
    }

    .data {
        display: flex;

        gap: 3px;
        justify-content: space-between;

        //@media screen and (max-width: 1800px) {
        //    padding-right: 8px;
        //    padding-left: 4px;
        //}
        //
        //@media screen and (max-width: 1600px) {
        //    padding-right: 4px;
        //}
    }

    .childPercent {
        display: flex;
        justify-content: flex-end;
        padding: 0;
        align-items: center;
        gap: 5px;

        span {
            font-size: 12px;
        }

        @media screen and (max-width: 1800px) {
            padding: 0 8px;
        }

        @media screen and (max-width: 1600px) {
            padding: 0 4px;
        }
    }
}

.alignItemsCenter {
    align-items: center;
    display: flex;
}

.noMarginTop {
    margin-top: 0 !important;
}

.aligned {
    display: flex;
    align-items: center;
    gap: 2px;
}