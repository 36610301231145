@import "root/css/variables.scss";

.blockBannerBox {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;

  .directorBox {
    align-self: end;
  }
  .contactUsBox {
    grid-column: span 2;
    img {
      width: 100%;
      height: auto;
    }
  }
  @media (max-width: $md) {
    grid-template-columns: 1fr;
    .contactUsBox {
      grid-column: span 1;
    }
  }
}
