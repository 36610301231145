@import "root/css/mixins";

.penaltyContainer {
  display: flex;
  width: 100%;

  .emptyDataMessage {
    background: white;
    padding: 15px;

    .securityTextBox {
      background-color: #8080801f;
      border: 2px dashed #80808061;
      border-radius: 10px;
      padding: 12px;
    }

    .securityText {
      color: rgba(0, 0, 0, 0.8);
      margin: 0;
    }
  }
  .containerTabs {
    display: flex;
    gap: 20px;
    width: 100%;
  }
}

.penaltyContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 24px;

  .content {
    display: grid;
    grid-auto-rows: minmax(30px, auto);
    grid-gap: 20px;
    margin-bottom: 24px;
    background-color: white;
    padding: 16px;
    grid-template-areas:
      "box5 box5 box5 box5 box5 box5 box5"
      "box0 box1 box1 box1 box6 box6 box6"
      "box0 box1 box1 box1 box6 box6 box6"
      "box8 box8 box8 box8 box8 box7 box7"
      "box8 box8 box8 box8 box8 box7 box7"
      "box3 box3 box3 box3 box3 box3 box3";

    @media screen and (max-width: 1800px) {
      grid-template-columns: 12fr;
      grid-template-areas:
        "box5 box5 box5 box5 box5 box5 box5"
        "box0 box1 box6 box6 box6 box6 box6"
        "box0 box1 box6 box6 box6 box6 box6"
        "box8 box8 box8 box8 box7 box7 box7"
        "box8 box8 box8 box8 box7 box7 box7";
    }

    @media screen and (max-width: 1600px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
      grid-template-areas:
        "box5 box5 box5 box5 box5 box5 box5"
        "box0 box1 box1 box1 box6 box6 box6"
        "box0 box1 box1 box1 box6 box6 box6"
        "box8 box8 box8 box8 box7 box7 box7"
        "box8 box8 box8 box8 box7 box7 box7";
    }

    @media screen and (max-width: 1440px) {
      grid-template-areas:
        "box5 box5 box5 box5 box5 box5 box5"
        "box0 box0 box0 box0 box1 box1 box1"
        "box0 box0 box0 box0 box1 box1 box1"
        "box6 box6 box6 box6 box7 box7 box7"
        "box8 box8 box8 box8 box8 box8 box8";
    }

    @media screen and (max-width: 1200px) {
      grid-template-areas:
        "box5 box5 box5 box5 box5 box5 box5 box5"
        "box0 box0 box0 box0 box1 box1 box1 box1"
        "box0 box0 box0 box0 box1 box1 box1 box1"
        "box6 box6 box6 box6 box6 box6 box6 box6"
        "box7 box7 box7 box7 box7 box7 box7 box7"
        "box8 box8 box8 box8 box8 box8 box8 box8";
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "box5 box5"
        "box0 box0"
        "box1 box1"
        "box6 box6"
        "box7 box7"
        "box8 box8";
    }
  }

  .box0 {
    grid-area: box0;

    @media screen and (max-width: 1800px) {
      min-width: 24vw;
    }

    @media screen and (max-width: 1024px) {
      min-width: calc(50vw - 100px);
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .box0Size {
    min-height: 329px;
  }

  .box1 {
    grid-area: box1;
    position: relative;
    @media screen and (max-width: 1800px) {
      min-width: 28vw;
    }

    @media screen and (max-width: 1024px) {
      min-width: calc(50vw - 100px);
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  .box1Size {
    min-height: 159px;
  }

  .box2 {
    grid-area: box2;
    @media screen and (max-width: 1800px) {
      min-width: 28vw;
    }

    @media screen and (max-width: 1024px) {
      min-width: calc(50vw - 100px);
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .box2Size {
    min-height: 159px;
  }

  .box3 {
    grid-area: box3;
  }

  .box4 {
    grid-area: box4;
  }

  .box5 {
    grid-area: box5;

    button {
      @media screen and (max-width: 960px) {
        font-size: 15px;
      }
    }

    @media screen and (max-width: 1800px) {
      min-width: 38vw;
    }

    @media screen and (max-width: 1600px) {
      min-width: 35vw;
    }
  }

  .box6 {
    grid-area: box6;
    height: 100%;
  }

  .box6Size {
    min-height: 271px;
  }

  .box7 {
    grid-area: box7;
    width: 100%;
  }

  .box7Size {
    height: 100%;
    min-width: 300px;

    @media (max-width: 360px) {
      min-width: 100%;
    }
  }

  .box8 {
    grid-area: box8;
  }

  .box8Size {
    min-height: 396px;
  }
}

@media (max-width: 1440px) {
  .content {
    gap: 10px !important;
  }
}
.widgetWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 600px) {
    flex-direction: row;
    transition: all 250ms linear;
  }
  .widgetBox {
    @media screen and (max-width: 600px) {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 1;
    }
    > div {
      min-height: 171px;
    }
  }
}

.penaltyDynamicContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  .containerCheckbox {
    max-width: 250px;
    overflow-y: auto;
    height: 275px;

    .checkBoxBlock {
      display: flex;
      align-items: flex-start;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
      padding: 6px 6px 6px 0;
      margin-bottom: 5px;
    }

    .checkBox {
      padding: 1px 10px 3px 0;
      //width: 80px;
    }

    @media screen and (max-width: 580px) {
      height: auto;
      padding-bottom: 20px;
      max-height: 275px;
    }
  }

  h4 {
    font-size: 18px;

    @media screen and (max-width: 1800px) {
      font-size: 16px;
    }
  }
}

.diagramDecription {
  background-color: white;
  padding: 10px;
  border: 1px solid;
}

.showDate {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.noDataMessage {
  position: absolute;
  backdrop-filter: blur(4px);
  background-color: rgba(white, 0.2);
  width: 100%;
  height: 100%;
  padding: 16px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 3px;
  z-index: 12;
  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 35px;
    background-color: rgba(white, 1);
    backdrop-filter: blur(7px);
    width: 70%;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    margin-top: 100px;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 959px) {
      width: 90%;
    }
    @media screen and (max-width: 599px) {
      width: 100%;
      padding: 16px;
    }

    .text {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      color: #43455c;
      line-height: 1.1em;
    }
  }
}

.boxLoader {
  position: relative;
  min-height: 100px;
  background-color: #fff;
  border-radius: 10px;
}

.loader {
  @include loader;
}
.tableWrap {
  margin-top: 24px;
  padding: 16px;
}

.controlBtn {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #cf3aa0;
  color: white;
}
