.miniContainer{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    h5 {
        font-size: 12px;
        font-weight: 400;
        color: #798097;
        text-transform: uppercase;
        margin: 0;
    }

    .deliveryContainer{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 0;

        .subjects{
            font-weight: 600;
            font-size: 18px;

            @media(max-width: 1800px) {
                font-size: 16px;
            }

            @media(max-width: 1600px) {
                font-size: 15px;
            }
        }

        //@media(max-width: 1800px) {
        //    padding: 0 8px;
        //}
        //
        //@media(max-width: 1600px) {
        //    padding: 0 4px;
        //}
    }

    .precentContainer{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 5px;
        padding: 0;

        //@media(max-width: 1800px) {
        //    padding: 0 8px;
        //}
        //
        //@media(max-width: 1600px) {
        //    padding: 0 4px;
        //}
    }

    .deliveryPrecent{
        padding: 0 ;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: space-between;

        //@media(max-width: 1800px) {
        //    padding: 0 8px;
        //}
        //
        //@media(max-width: 1600px) {
        //    padding: 0 4px;
        //}
    }

    .precentTitle {
        display: flex;
        align-items: center;
        font-size: 14px;
        //padding-left: 16px;
    }

    .precent{
        font-size: 14px;
    }
}

.italic {
    font-style: italic;
}