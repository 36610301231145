@import "root/css/mixins";

.indicatorsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  .keyIndicatorContainer {
    display: grid;
    width: 100%;
    background: white;
    gap: 25px;
    grid-template-columns: repeat(5, minmax(0, 1fr));

    padding: 0 12px;

    .container {
      display: flex;
      width: 20%;

      background-color: white;
      border-radius: 8px;

      padding: 3px;
      height: 200px;
    }

    .boxKeyIndicators {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-right: 2px solid rgb(228, 216, 216);
      padding-right: 14px;
      background-color: white;
      padding-top: 7px;
      padding-bottom: 10px;

      &:last-child {
        border-right: none;
        padding-right: 0;
      }

      .boxBorder {
        width: 10px;
        height: 20px;
        border: 1px solid;
      }
    }
  }

  #tabElements {
    background-color: blue;
  }

  .diagramContainer {
    width: 2000px;
  }

  .boxDiagramaContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: white;
    padding: 20px 20px;

    .overflowHidden {
      overflow: hidden;
    }

    > div:first-child {
      max-width: 80vw;
      overflow: hidden;

      @media screen and (min-width: 2660px) {
        max-width: 84vw;
      }

      @media screen and (max-width: 1920px) {
        max-width: 72vw;
      }

      @media screen and (max-width: 1800px) {
        max-width: 72vw;
      }

      @media screen and (max-width: 1700px) {
        max-width: 72vw;
      }

      @media screen and (max-width: 1600px) {
        max-width: 70vw;
      }

      @media screen and (max-width: 1440px) {
        max-width: 100vw;
      }

      @media screen and (max-width: 1200px) {
        padding: 10px;
      }
    }
    > div:last-child {
      margin-top: 20px;
      box-shadow: none;
      gap: 20px;
    }

    .menuTab {
      margin-left: 12px;
      @media screen and (max-width: 1200px) {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 1440px) {
      flex-direction: column;
    }
  }
  .tableContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;

    .menuTabable {
      margin-left: 16px;
    }
  }
}

.tabContainer {
  display: flex;
  align-items: center;
}

.widgetBox {
  position: relative;
  min-height: 264px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}

.box {
  position: relative;
  min-height: 440px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}

.loader {
  @include loader;
}
