.diagramDecription{
    background-color:white;
    width: 100%;
    padding: 20px;
    box-shadow: -1px 1px 8px 4px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    .legendsBox {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .hintTitle {
        font-size: 14px;
        color: #43455c;
        margin-bottom: 10px;
        margin-top: 0;
    }
    .legends{
        display: flex;
        gap: 5px;
        align-items: baseline;
        max-width: 270px;

        .circle{
            width: 6px;
            min-width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #00C85A;
        }

        .secondCircle{
            background-color: blue;
            width: 6px;
            min-width: 6px;
            height: 6px;
            border-radius: 50%;
        }

        .thirdCircle{
            background-color: red;
            width: 6px;
            min-width: 6px;
            height: 6px;
            border-radius: 50%;
        }

        .fourthCircle{
            background-color: #ffa500;
            width: 6px;
            min-width: 6px;
            height: 6px;
            border-radius: 50%;
        }

        .title {
            font-size: 16px;
            color: #43455c;
        }
    }
    .value {
        font-size: 14px;
        color: #798097;
        padding-left: 10px;
    }
}

.legendItem {
    position: relative;
}