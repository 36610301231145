.cardContainer{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
    min-width: 260px;

    h4 {
        font-size: 12px;
        font-weight: 400;
        color: #798097;
        text-transform: uppercase;
        margin: 0;

        //@media(max-width: 1800px) {
        //    font-size: 16px;
        //}
    }

    .deliverBox{
        display: flex;
        padding: 0;
        justify-content: space-between;
        width: 100%;

        span {
            @media(max-width: 1800px) {
                font-size: 16px;
            }

            @media(max-width: 1600px) {
                font-size: 15px;
            }
        }

        .subjects{
            font-weight: 600;
            font-size: 18px;

            @media(max-width: 1800px) {
                font-size: 16px;
            }

            @media(max-width: 1600px) {
                font-size: 15px;
            }
        }

        //@media(max-width: 1800px) {
        //    padding: 0 8px;
        //}
        //
        //@media(max-width: 1600px) {
        //    padding: 0 4px;
        //}
    }

    .iconQuanity{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 5px;
        padding: 0;

        //@media(max-width: 1800px) {
        //    padding: 0 8px;
        //}
        //
        //@media(max-width: 1600px) {
        //    padding: 0 4px;
        //}
    }

    .deliveryPrecent{
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
        height: 27px;
        justify-content: space-between;

        //@media(max-width: 1800px) {
        //    padding: 0 8px;
        //}
        //
        //@media(max-width: 1600px) {
        //    padding: 0 4px;
        //}
    }

    .precentTitle {
        display: flex;
        align-items: center;
        font-size: 14px;

    }

    .precent{
        font-size: 14px;
    }

    @media(max-width: 1800px) {
        min-width: 26vw;
        padding: 5px 5px 5px 8px;
    }

    @media(max-width: 1600px) {
        min-width: 27vw;
    }

    @media(max-width: 1500px) {
        min-width: 26vw;
    }

    @media(max-width: 1160px) {
        min-width: 24vw;
    }

    @media(max-width: 1024px) {
        min-width: calc(50vw - 68px);
    }

    @media(max-width: 959px) {
        min-width: calc(50vw - 60px);
    }

    @media(max-width: 580px) {
        min-width: calc(50vw - 24px);
    }

}

.italic {
    font-style: italic;
}