
    .legendsBox {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .legends{

            display: flex;
            gap: 5px;
            align-items: baseline;
            .circle{
                width: 6px;
                min-width: 6px;
                height: 6px;
                border-radius: 50%;
            }
           

        }
        .title {
            font-size: 16px;
            color: #43455c;
        }
        .value {
            font-size: 14px;
            color: #798097;
            padding-left: 10px;
        }
     
    }
   

