@import "root/css/variables.scss";

.root {
	position: relative;
	background-color: lightgray;
	border-radius: 3px;
	width: 40px;
	height: 40px;
	user-select: none;
	object-fit: contain;
	object-position: center;

	&[data-loaded="true"] {
		background-color: white;
	}
}
