@import "root/css/variables.scss";

.root {
	position: relative;
	display: flex;
	align-items: flex-start;

	.dot {
		position: relative;
		flex: none;
		width: 6px;
		height: 6px;
		border-radius: 100%;
		margin-right: 5px;
		margin-top: 6px;
	}

	.info {
		display: flex;
		flex-direction: column;

		.name {
			font-size: 14px;
			color: $gun-powder-color;
		}
		.subtitle {
			font-size: 12px;
			color: $faded-color;
		}
	}
}
