.wrapperWidgets{
  position: relative;
  @media screen and (max-width: 600px) {
    height: 100%;
  }

}

.paidWidgetsContainer{
     display: flex;
    flex-direction: column;
     gap: 10px;
   height: 100%;
   @media screen and (max-width: 1440px) {
     display: grid;
     grid-template-columns: repeat(2, minmax(0, 1fr));
   }

   @media screen and (max-width: 959px) {
     grid-template-columns: repeat(2, minmax(0, 1fr));
   }

   //for carousel
   @media screen and (max-width: 600px) {
     display: flex;
     flex-direction: row;
     transition: all 250ms linear
   }
 }

 .controlBtn{
   position: absolute;
   z-index: 1;
   top: 50%;
   transform: translateY(-50%);
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background-color: #CF3AA0;
   color: white;
 }