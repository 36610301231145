.cardIndicatorsContainer{
	display: grid;
	width: 100%;
	gap: 30px;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	padding: 24px 20px;
	background-color: white;

	.cardsContainer{

		.boxBorder {
			width: 10px;
			height: 20px;
			border: 1px solid;
		}

		//.cardItem {
		//	display: flex;
		//	gap: 20px;
		//	width: 616px;
		//	>div {
		//		width: 310px;
		//	}
		//}

		@media screen and (max-width: 1440px) {
			width: 33%;
			text-align: left;
			margin-bottom: 10px;
			display: inline-block;
			padding: 0 5px;
		}

		@media screen and (max-width: 850px) {
			width: 50%;
		}

		@media screen and (max-width: 580px) {
			width: 100%;
		}
	}

	.cardsContainer:last-child {
		> div {
			@media screen and (max-width: 850px) {
				display: flex;
				gap: 10px;
				align-items: baseline;

				> div:first-child {
					width: 50%;
				}

				> div:last-child {
					width: 50%;
					margin: 0;
				}
			}

			@media screen and (max-width: 580px) {
				width: 100%;
				display: block;

				> div:first-child {
					width: 100%;
				}

				> div:last-child {
					width: 100%;
					margin-top: 10px;
				}
			}
		}

		@media screen and (max-width: 850px) {
			width: 100%;
		}
	}

	@media screen and (max-width: 1440px) {
		display: inline-block;
		text-align: center;
	}
	//@media screen and (max-width: 600px) {
	//	display: flex;
	//}

}
