.cicleBox {
  // background-color: white;
  // border-radius: 3px;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  .descriptionCicrleBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;

    @media screen and (max-width: 1024px) {
      min-width: 400px;
    }

    @media screen and (max-width: 700px) {
      flex-direction: column-reverse;
      min-width: 0;
    }
  }

  .title {
    display: flex;
    align-items: center;
  }

  .title h4 {
    font-size: 18px;
    margin: 0;
    @media screen and (max-width: 1800px) {
      font-size: 16px;
    }
  }

  .circleContainer {
    display: flex;
    padding: 0;
    justify-content: space-around;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 959px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.cicleDescription {
  display: flex;
  flex-direction: column;
  /* grid-gap: 16px; */
  gap: 6px;
  padding-left: 0px;
  margin-top: 10px;
  max-height: 195px;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 170px;

  @media screen and (max-width: 1200px) {
    min-width: 200px;
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    min-width: 200px;
    width: 100%;
  }

  @media screen and (max-width: 700px) {
    max-width: 480px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    h5 {
      display: none;
    }
  }
}

.scrollableBlock {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}
.negativeChartProps {
  @media screen and (max-width: 1440px) {
    height: 390px !important;
  }
}
.legenditem {
  display: flex;
  margin-bottom: 8px;
}

.diagramDecription {
  background-color: white;
  z-index: 99999;
  width: 100%;
  padding: 20px;
  box-shadow: -1px 1px 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  .legendsBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .legends {
      display: flex;
      gap: 5px;
      align-items: baseline;
      .circle {
        width: 6px;
        min-width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
    .title {
      font-size: 16px;
      color: #43455c;
    }
    .value {
      font-size: 14px;
      color: #798097;
      padding-left: 10px;
    }
  }
}
