.cardsContainer {
    width: 100%;
    background-color: #f0f2f5;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media(max-width: 1440px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        max-width: none;
    }

    @media(max-width: 850px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    //for carousel
    @media screen and (max-width: 600px) {
        display: flex;
        transition: all 250ms linear
    }
}
.cardsWrapper{
    position: relative;
    max-width: 30%;
    width: 100%;
    @media (max-width: 1400px) {
        max-width: 100%;
    }
}

.cardWrapper {
    @media(max-width: 600px) {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
    }
}

.controlBtn{
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #CF3AA0;
    color: white;
}