@import "root/css/variables.scss";

.notificationBox {
  min-height: 100px;
  // background-color: $main-color;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  border-radius: 15px;
  .notificationInnerBox {
    padding: 2rem;
    position: relative;
    min-height: inherit;
    border-radius: inherit;
    width: calc(100% - 5px);
    background-color: white;
    .closeIcon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:hover {
        svg {
          opacity: 0.6;
          color: $main-color;
        }
      }
      svg {
        width: inherit;
        height: inherit;
        color: $faded-color;
      }
    }
  }
}

.bannerV2Root {
  z-index: 10000;
  .bannerV2Content {
    display: flex;
    padding: 2rem;
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    text-align: center;
  }
  .bannerV2Button {
    height: 50px;
    text-transform: none;
    font-size: 16px;
    color: white;
  }
  .redButton {
    background-color: #c54b6c;
  }
  .yellowButton {
    background-color: #f7ce76;
  }
  .greenButton {
    background-color: #98d4bb;
  }
  .purpleButton {
    background-color: #cf3aa0;
  }
}
