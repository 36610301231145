@import "root/css/variables.scss";

.root {
	font-size: 14px;
}
.outlined {
	color: $faded-color;
	border: 1px solid $border-color;
	border-radius: 4px;
}
.selected {
	color: $main-color;
	background-color: transparent !important;
	border: 1px solid $main-color;
}
