@import "root/css/variables.scss";

.selectFinancialContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  align-items: center;

  .datePickerStyle {
    background-color: #cf3aa0;
  }
  .titleWrapper{
    h5{
      font-size: 20px;
      color: #43455C;
      @media screen and (max-width: 600px) {
        font-size: 24px;
        align-self: flex-start;
      }
    }
  }

  .datePicker {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    background-color: white;
    height: 47px;
    padding: 0 12px;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
      height: auto;
      flex-wrap: wrap;
    }

    .selectStyle {
      text-align: center;
      width: 120px;
      height: 50px;
      border: 1px solid #cf3aa0 !important;
    }

    .selectStyleDefault {
      text-align: center;
      width: 120px;
      height: 50px;
      border: 1px solid #f0f2f5 !important;
    }

    label {
      display: none;
    }

    .coverBox {
      display: flex;
      gap: 12px;
      align-items: center;
      @media screen and (max-width: 810px) {
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      @media screen and (max-width: 550px) {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
      }

      .selectItem {
        label {
          display: block;
          top: 7px;
        }
      }

      button[class*="Mui-disabled"] {
        background-color: transparent;
      }
    }

    @media screen and (max-width: 810px) {
      height: fit-content;
      padding: 6px 12px;
      display: flex;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 550px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
    }
  }
  .borderSelects {
    width: 100%;
    background-color: #f0f2f5;
  }
}
.financialSelectContainer {
  display: flex;
  gap: 5px;
}
.boxSelect {
  display: flex;
  flex-direction: column;
}

.bttnLogicContainer {
  display: flex;
  gap: 15px;
}

.standartButton {
  width: 220px;
  height: 52px;
  line-height: 100%;
  transition: all ease 0.5s;
  box-shadow: none;

  //@media screen and (max-width: 1800px) {
  //  //font-size: 12px;
  //  //height: 36px;
  //  width: 200px;
  //}

  @media screen and (max-width: 550px) {
    width: 100%;
  }
}

.logicBttnSizedefault {
  color: #cf3aa0 !important;
  background-color: white !important;
  border: 1px solid hsl(319deg 61% 52% / 50%);
}

.logicBttnSizedefault:hover {
  background-color: rgb(207 58 160 / 15%) !important;
}

.bttnSize {
  //background-color: #cf3aa0!important;
  color: white !important;
  //padding: 5px 14px;
}
.isLoading {
  z-index: 15;
}

.buttonBox {
  display: flex;
  gap: 10px;
}

.containerDate {
  margin-top: 15px;
}

.financialSelectContainer {
  display: flex;
  padding-left: 20px;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  .boxSelect {
    display: flex;
    flex-direction: column;
  }
}

.selectStyle {
  text-align: center;
  width: 120px;
  height: 50px;
}

.selectStyleDefault {
  text-align: center;
  width: 120px;
  height: 50px;
}

.bttnLogicContainer {
  display: flex;
  gap: 15px;
}

.isLoading {
  z-index: 15;
}

.buttonBox {
  display: flex;
  gap: 10px;
}

.containerDate {
  margin-top: 15px;
}

.selectStyleDefault {
  color: #798097 !important;
  cursor: pointer;
  transition: all ease 0.5s;
  font-weight: 500;
}

.selectStyleDefault:hover {
  color: #cf3aa0 !important;
}

.selectStyle {
  color: #cf3aa0 !important;
  cursor: pointer;
  font-weight: 500;
}

.selectSize > div {
  //width: 200px;
  //padding: 8.5px 16px !important;
}

.selectMonthAsDateBlock {
  @media screen and (max-width: 550px) {
    width: 100%;
  }
}

.selectMonthAsDate {
  width: 300px;
  height: 52px;
  transition: all ease 0.5s;

  fieldset {
    border: 1px solid #d6dae0;
  }

  //svg {
  //  color: #cf3aa0 !important;
  //}

  //@media screen and (max-width: 1800px) {
  //  font-size: 12px;
  //  height: 36px;
  //  width: 280px;
  //}

  @media screen and (max-width: 550px) {
    width: 100%;
  }
}

.selectMonthAsDateLabel {
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 14px;

  fieldset {
    border-width: 1px !important;
  }

  //@media screen and (max-width: 1800px) {
  //  font-size: 12px;
  //}
}

//.selectMonthAsDate:hover {
//  background-color: rgba(207, 58, 160, 0.15) !important;
//  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
//    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
//  //fieldset {
//  //  border: 1px solid hsla(319, 61%, 52%, 0.5);
//  //  border-color: hsla(319, 61%, 52%, 0.5) !important;
//  //}
//}

.monthIsSelected {
  //color: white !important;
  //background-color: #cf3aa0 !important;
  //border: none !important;

  //svg {
  //  color: white !important;
  //}
  //
  //fieldset {
  //  border: none !important;
  //}
  //
  //div {
  //  width: 80%;
  //}
}

//.monthIsSelected:hover {
//  background-color: rgb(144, 40, 112) !important;
//  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
//    0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
//}

.monthIsSelectedLabel {
  display: none !important;
}

#select-month.MuiInputLabel-shrink {
  left: auto;
  right: auto;
}

.actionsBtn {
  display: flex;
  gap: 5px;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 3;
}