@import "./variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;700&display=swap");

html {
  width: 100%;
  //height: 100%;
  background-color: $background-color !important;
  vertical-align: top;
  position: relative;
}

body {
  width: 100%;
  //height: 100%;
  -webkit-tap-highlight-color: rgba(black, 0);
  font-size: 16px;
  background-color: $background-color !important;
  position: relative;
}

* {
	vertical-align: inherit;


	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		cursor: pointer !important;
	}

	::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 3px;
	}

	::-webkit-scrollbar-thumb {
		background: #cf3aa0;
		border-radius: 3px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #cf3aa0;
		border-radius: 3px;
	}

}

#root {
  position: relative;
  min-height: 100vh;
  //overflow-x: hidden;
}

.recharts-cartesian-grid-vertical {
  line {
    stroke-dasharray: none;
  }
}

.date-picker-select .MuiSelect-selectMenu {
  padding-top: 15px;
  padding-bottom: 15px;
}

.rdrDayToday .rdrDayNumber span:after {
  background: $main-color !important;
}

.MuiSelect-outlined {
	padding: 14px 16px !important;

}

.notistack-SnackbarContainer {
	z-index: 15000;
  @media screen and (max-width: $md) {
    margin-top: 50px;
  }
}
