@import "root/css/variables.scss";

.paper {
	width: calc(100% - #{$drawer-minimized-width});
	@media screen and (max-width: $md) {
		width: 100%;
	}

	.closeIconWrapper {
		$wrapper-size: 24px;
		$icon-size: 18px;
		position: absolute;
		top: 22px;
		right: 22px;
		width: $wrapper-size;
		height: $wrapper-size;
		padding: ($wrapper-size - $icon-size) * 0.5;
		z-index: 2;
		outline: none;

		.closeIcon {
			position: relative;
			width: $icon-size;
			height: $icon-size;
			color: $faded-color;
			cursor: pointer;
		}
	}

	.inner {
		position: relative;
		width: 100%;
		height: 100%;
	}
}
