@import "root/css/variables";

.wrapperBlock {
  background: $background-color-total;
  padding: 10px 20px 10px 20px;

  @media screen and (max-width: 600px) {
    padding: 0px;
  }
}

.financialSelectContainer {
  display: flex;
  align-items: center;
  height: 48px;
  gap: 12px;

  > div {
    width: 20% !important;

    @media screen and (max-width: 1800px) {
      width: 100% !important;
      margin: 0;
    }
  }

  .boxSelect {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1800px) {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    height: auto;
    padding: 5px 12px;
    gap: 6px;
  }

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 580px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.selectStyle {
  color: $main-color !important;
  cursor: pointer;
  font-weight: 500;
}

.selectStyleDefault {
  color: $faded-color !important;
  cursor: pointer;
  transition: all ease 0.5s;
  font-weight: 500;
}

.selectStyleDefault:hover {
  color: $main-color !important;
}

.noXPadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.searchInSelect {
  width: 90%;
  margin: 0 auto 8px;
  display: flex;

  input {
    padding: 8.5px 12px;
  }
}

.bttnLogicContainer {
  display: flex;
  gap: 15px;
}

.buttonBox {
  display: flex;
  gap: 10px;
}

.containerDate {
  margin-top: 15px;
}

.resetFiltersButton {
  height: 40px;
  color: $main-color !important;
  border: 1px solid hsl(319deg 61% 52% / 50%);
  transition: all ease 0.5s;
  line-height: 100%;
  white-space: nowrap;
  width: 20%;
  padding: 6px 5px;
}

.resetFiltersButton:hover {
  background-color: rgb(207 58 160 / 15%) !important;
}

.selectItem {
  width: 20%;
  margin: 5px;
}

.selectSize > div {
  padding: 8.5px 16px !important;
}

.clearFiltersBlock {
  height: 48px;
  margin-bottom: 12px;
  background: $background-color-total;
  padding: 6px 12px;
  transition: all ease 0.5s;
}

.ClearButtonBox {
  height: 100%;
  color: #000;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  background-color: #eef4fa;
  border: 1px solid #8dc5fe;
  border-radius: 3px;
  transition: all ease 0.5s;
  .clearButton {
    color: #212529;
    height: 18px;
    width: 18px;
    .clearIcon {
      height: 18px;
      width: 18px;
      font-weight: 700;
    }
  }
}

.ClearButtonBox:hover {
  background-color: #8dc5fe4a;
}

.applyButtonBox {
  padding: 6px;
  min-width: fit-content;

  @media screen and (max-width: 1280px) {
    min-width: auto;
    max-width: 100%;
  }
}

.withBth {
  padding-bottom: 44px;
}

.option {
  padding: 10px 8px 10px 0;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid rgb(128 128 128 / 16%);
  cursor: pointer;
  white-space: nowrap;

  span {
    padding: 0 5px 0 0;
    color: $faded-color;
    transition: all ease 0.5s;
  }
}

.option:hover {
  span {
    color: $main-color;
  }

  .selectStyleDefault {
    color: $main-color !important;
  }
}

.fSize15 {
  font-size: 16px;
  border: none;
}

.autocompleteWrap {
  position: relative;
}

.btn {
  position: absolute;
  left: 0;
  z-index: 1000000;
}

.bthChoose {
  text-transform: none;
  font-weight: 400;
  width: 100%;
  height: 50px;
  font-size: 1rem;
  justify-content: space-between;
  border-color: $border-color;
  color: $gun-powder-color;
  @media (max-width: 600px) {
    justify-content: space-between;
  }

  &:hover {
    background-color: $background-color-total;
    .clearButtonInSelector {
      display: flex;
    }
  }
}

.drawerWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  div[class*="MuiPaper-elevation"] {
    @media (max-width: 420px) {
      width: 100%;
      overflow-x: auto;
    }
  }
}
.drawerPaper {
  width: 100%;
}
.closeIconBlock {
  @media (max-width: 959px) {
    margin-top: 65px;
  }
}

.articleList {
  display: grid;
  grid-template-columns: repeat(5, 120px);
  gap: 15px;
  box-sizing: border-box;
  padding: 8px 8px 160px 8px;
}

.articleWrap {
  width: 120px;
  min-height: 150px;
  background-color: #f9f9fd;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  box-sizing: border-box;
}

.articleWrapActive {
  border-radius: 20px;
  border: solid 1px $main-color;
}

.imgArticle {
  width: 90px;
  height: 110px;
  background-color: #f9f9fd !important;
}

.article {
  font-size: 1rem;
  text-align: center;
  color: $tooltip-color;
  font-weight: 500;
  word-break: break-word;
}

.checkbox {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  display: none;
}

.checkboxActive {
  display: block;
}

.label {
  margin-right: 0;
  margin-left: 0;
  position: relative;
}

.btnSaveArticles {
  margin: 0 10px 10px 10px;
}

.countMessage {
  margin-left: 10px;
}

.tooltip {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.viewBlock {
  display: flex;
  gap: 15px;
}

.activeIcon {
  fill: $main-color;
}

.controlBlock {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  background-color: $background-color-total;
  box-sizing: border-box;
  width: 675px;
}

.buttonChip {
  justify-content: flex-start;
  font-size: 15px;
  padding-left: 6px;
  height: 50px;
}

.buttonLabel {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.00938em;
  padding: 0 4px;
  display: block;
  transform-origin: top left;
  overflow: hidden;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(10px, -6px) scale(0.75);
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  user-select: none;
  background-color: $background-color-total;
}

.clearButtonInSelector {
  margin-left: auto;
  display: none;
  padding: 4px;
}

.clearIconInSelector {
  color: rgba(0, 0, 0, 0.54);
  width: 20px;
  height: 20px;
}

.disabled {
  cursor: default;
  color: rgba(0, 0, 0, 0.38);
}

.disabledButton {
  background-color: $background-color-total !important;
  opacity: 0.6;
}

.disabledButton:hover {
  .clearButtonInSelector {
    display: none;
  }
}

.searchBlock {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
  gap: 10px;
}

.inputSearch {
  width: 100%;
  margin-bottom: 20px;
  div[class*="MuiOutlinedInput-root"] {
    padding-right: 10px;
  }

  input {
    padding: 0 10px;
    height: 50px;
  }
}

.emptyMessage {
  white-space: nowrap;
}
