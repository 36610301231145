@import "root/css/variables.scss";

.root {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	border-radius: 0 3px;
	width: 12px;
	height: 12px;
	margin: 0 4px;
	background-color: $main-color;
	padding: 1px;

	.icon {
		position: relative;
		width: 10px;
		height: 10px;
		color: white;
		vertical-align: top;
	}
}
