@import "root/css/variables.scss";

.root {
	border: 1px solid $border-color;
	border-radius: 3px;
	height: 100%;

	.header {
		display: flex;
		position: relative;

		.info {
			flex-grow: 1;

			.name:hover {
				span {
					border-bottom: 2px dashed #cf3aa0;
					width: fit-content;
				}
			}

			.name {
				font-size: 24px;
				line-height: 28px;
				margin-bottom: 12px;
				color: $eerie-black-color;
				word-break: break-word;
			}



			.fadedText {
				font-size: 16px;
				line-height: 22px;
				color: $faded-color;
				word-break: break-word;
			}
		}

		// .buttonWrapper {
		// 	position: absolute;
		// 	right: 24px;
		// 	top: 24px;
		// 	z-index: 1000;
		// 	flex: none;
		// }
	}

	.lastUpdate {
		color: $eerie-black-color;
	}

	.oAuthText {
		color: $main-color;
	}

	.editButtons {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

		@media screen and (max-width: $sm) {
			flex-direction: column;
			align-items: stretch;
		}

		&>*:first-child {
			flex-grow: 1;
		}

		&>*:not(:last-child) {
			margin-right: 16px;

			@media screen and (max-width: $sm) {
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}

	.emptyButtons {
		display: flex;
		// justify-content: space-between;
		// align-items: flex-end;
		flex-direction: row-reverse;

		@media screen and (max-width: $sm) {
			flex-direction: column;
			align-items: stretch;
		}

		&>*:first-child {

			// margin-right: 16px;
			@media screen and (max-width: $sm) {
				margin-right: 0;
				// margin-bottom: 10px;
			}
		}
	}

	:global {
		.MuiToggleButton-root {
			padding: 11px 5px;

			&.Mui-selected {
				background-color: #cf3aa0;
				color: white;
			}
		}
	}

	.new {
		font-size: 16px;
		line-height: 18.4px;

		.title {
			color: $gun-powder-color;
			font-weight: 500;
		}

		.text {
			color: $faded-color;
		}
	}

	.errorText {
		color: red;
	}
}

.buttonWrapper {
	position: absolute;
	align-items: center;
	display: flex;
	gap: 8px;
	right: 24px;
	top: 24px;
	z-index: 1000;
}

.is_revoked {
	background-color: #fde4eb !important;
	text-decoration: line-through;
	cursor: default;
}

.read_only {
	background-color: #fff7e1 !important;
}

.addNewChip {
	background-color: #cf3aa0 !important;
	color: #fff !important;
	cursor: pointer;
}

.addNewChip:hover {
	background-color: #e12da9 !important;
}


.oAuth {
	// background-color: #cf3aa0 !important;
	border: 1px solid #cf3aa0;
	background-color: #fff !important;
	color: #cf3aa0 !important;
	cursor: pointer;
}

.oAuth:hover {
	border: 1px solid #e12da9;
	background-color: #F5D8EC !important;
}

// .oAuthBg {
// 	background-color: #F5D8EC !important;
// 	// cursor: auto !important;
// }

.oAuthBg:hover {
	background-color: #F5D8EC !important;
	// background-color: #fff !important;
}

.chip {
	padding: 8px 10px;
	width: fit-content;
	border-radius: 3px;
	background-color: #f2f3f7;
	font-size: 12px;
	color: #798097;
	line-height: 1.2em;
	white-space: normal;
	min-height: 14px;
}