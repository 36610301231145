.diagramDescription {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: -1px 1px 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;

  .hintTitle {
    font-size: 14px;
    color: #43455c;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .periodContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .legends {
      display: flex;
      flex-direction: column;

      .period {
        display: flex;
        align-items: center;
        gap: 5px;

        .textSize {
          font-size: 16px;
          color: #43455c;
        }
        .valueSize {
          color: #f0f2f5;
        }
      }
    }

    .circle {
      min-width: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }

    .circleCurrent {
      background-color: rgb(0, 200, 90);
    }
    .circlePrevent {
      background-color: blue;
    }

    .circleTrend {
      background-color: #ffa500;
    }
    .circleOrders {
      background-color: red;
    }

    .circleEvents {
      background-color: #cf3aa0;
    }

    .circleCurrentForecast {
      background-color: blue;
    }

    .circlePreventForecast {
      background-color: #ffa500;
    }

    .circleTrendForecast {
      background-color: rgb(0, 200, 90);
    }

    .circleOrdersForecast {
      background-color: red;
    }

    .circleCurrentPromotion {
      background-color: #03a9f5;
    }
    .circlePreventPromotion {
      background-color: #2b6b71;
    }

    .circleTrendPromotion {
      background-color: #c0ca33;
    }
    .circleOrdersPromotion {
      background-color: #fe5722;
    }
  }
}
.valueContainer {
  font-size: 14px;
  color: #798097;
  padding-left: 10px;
}

.conversion {
  color: #8e25c3;
  margin-left: 5px;
}
