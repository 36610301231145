.cicleBox {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 9px;
  width: 100%;
  position: relative;
  //height: 411px;

  .scrollableBlock {
    overflow-x: auto;
    overflow-y: auto;
    height: 95%;
    // max-height: 240px;

    @media screen and (max-width: 1440px) {
      max-height: none;
    }
  }

  .descriptionCicrleBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 95%;
    min-width: 100%;
    flex-direction: column;

    .cicleDescription {
      width: 100%;
      padding-left: 15px;
      margin-top: 12px;

      @media screen and (max-width: 1440px) {
        width: auto;
        margin: 0;
      }
    }

    .pieBox {
      width: 100%;
      justify-content: center;
      display: flex;
      margin-top: -60px;
      @media screen and (max-width: 1440px) {
        width: auto;
        margin: 0;
      }
    }

    @media screen and (max-width: 1440px) {
      flex-direction: row-reverse;
    }

    @media screen and (max-width: 580px) {
      min-width: 480px;
    }
  }

  .title {
    display: flex;
    align-items: center;
  }

  .title h4 {
    font-size: 18px;
    color: #43455c;

    @media (max-width: 1800px) {
      font-size: 16px;
    }
  }

  .circleContainer {
    display: flex;
    justify-content: space-around;
  }

  @media screen and (min-width: 1920px) {
    width: 70%;
  }

  @media screen and (max-width: 1920px) {
    width: 70%;
  }

  @media screen and (max-width: 1440px) {
    width: 100%;
  }

  @media screen and (max-width: 850px) {
    width: 100%;
  }
}

.cicleDescription {
  display: flex;
  flex-direction: column;
  /* grid-gap: 16px; */
  gap: 6px;
  padding-left: 0px;
  margin-top: 10px;
}

.positiveChartProps {
  @media screen and (min-width: 1920px) {
    width: 55%;
  }

  @media screen and (max-width: 1920px) {
    width: 60%;
  }

  @media screen and (max-width: 1440px) {
    width: 600px;
    height: 340px;
  }

  @media screen and (max-width: 850px) {
    width: 100%;
    overflow-x: auto;
  }
}

.diagramDecription {
  min-width: 280px;
  padding: 4px 10px;
  background-color: white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 5px;

  .circle {
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }

  span {
    white-space: nowrap;
  }
}
.diagramDecription {
  background-color: white;
  width: 100%;
  padding: 20px;
  box-shadow: -1px 1px 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  .legendsBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .legends {
      display: flex;
      gap: 5px;
      align-items: baseline;
      .circle {
        width: 6px;
        min-width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
    .title {
      font-size: 16px;
      color: #43455c;
    }
    .value {
      font-size: 14px;
      color: #798097;
      padding-left: 10px;
    }
  }
}

.wrap {
  overflow-y: auto;
}

.descriptionBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px 20px;
  gap: 5px;

  .cube {
    width: 22px;
    min-width: 10px;
    height: 22px;
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: start;
  }
}

.flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
