@import "root/css/variables.scss";

.selector {
  border: 1px solid $border-color;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  min-width: 200px;
  height: 50px;
  padding: 1rem;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  svg {
    color: $faded-color;
  }
  & > *:first-child {
    color: $gun-powder-color;
  }
  &[data-disabled="false"]:hover {
    border-color: $background-hover-color;
    svg {
      color: $main-color;
    }
  }
  &[data-empty="false"] {
    background-color: #eef4fa;
  }
  &[data-disabled="true"] {
    opacity: 0.5;
  }
}
