@import "root/css/variables.scss";

.root {
  position: absolute;
  backdrop-filter: blur(4px);
  background-color: rgba(white, 0.1);
  width: 100%;
  height: 100%;
  padding: 16px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  z-index: 12;

  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 35px;
    background-color: rgba(white, 0.7);
    backdrop-filter: blur(7px);
    width: 70%;
    border-radius: 3px;
    @media screen and (max-width: $md) {
      width: 90%;
    }
    @media screen and (max-width: $sm) {
      width: 100%;
      padding: 16px;
    }

    .text {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 24px;
      color: $gun-powder-color;
      margin-bottom: 15px;
      line-height: 1.1em;
    }

    .childrenBox {
      position: relative;
      width: 100%;
      padding: 25px;
      border-radius: 4px;
      border: 1px solid $pagination-border-shadow-color;
      background-color: white;

      .progress {
        color: $main-color;
      }
    }
  }
}
