.promotWidgetsContainer{
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 16px;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
    @media screen and (max-width: 600px){
        flex-direction: row;
        transition: all 250ms linear
    }

    .separateCardBlock {
        width: calc(100vw / 5);
        @media screen and (max-width: 800px) {
            width: 100%;
        }
        > div {
            margin-top: 0 !important;
            height: 100%;
        }
        @media screen and (max-width: 600px) {
            display: flex;
            width: 100%;
            flex-shrink: 0;
            flex-grow: 1;
            >div {
                width: 100%;
                flex-shrink: 0;
                flex-grow: 1;
            }
        }
    }

    .fourWidgetsBlock {
        width: calc(100vw - (100vw / 5));
        display: grid;
        gap: 8px;
        @media screen and (max-width: 800px) {
            width: 100%;
        }
        @media screen and (max-width: 600px) {
            display: flex;
        }
    }

    .widgets {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        @media screen and (max-width: 800px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media screen and (max-width: 600px) {
            display: flex;
            flex-direction: row;
            width: 100%;
            > div {
                width: 100%;
                flex-shrink: 0;
                flex-grow: 1;
            }
        }
        > div {
            margin-top: 0 !important;
        }
    }

    @media screen and (max-width: 1180px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-top: 0;
    }

}

.bttnSize {
    height: 40px;
    color: white !important;
    line-height: 100%;
    opacity: 0;
}

.settingsLine {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    padding: 5px 12px;
    justify-content: end;
    display: flex;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
    }


    .checkboxes {
        display: flex;
        align-items: center;
        opacity: 0;

        .item {
            display: flex;
            align-items: center;
        }
    }
}

.controlBtn{
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #CF3AA0;
    color: white;
}