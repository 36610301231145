@import "root/css/mixins";

.promotionMedia {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;
}

.innerBox {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  margin-top: -10px;
}

.gridBox {
  display: grid;
  grid-auto-rows: minmax(30px, auto);
  grid-gap: 10px;
  grid-template-columns: 12fr;
  grid-template-areas:
    "box2 box4"
    "box1 box1"
    "box3 box3";

  @media screen and (max-width: 959px) {
    grid-template-areas:
      "box2 box2"
      "box4 box4"
      "box1 box1"
      "box3 box3";
  }
}

.box1 {
  grid-area: box1;

  > div {
    padding: 9px;
  }
}

.box2 {
  grid-area: box2;
}

.box3 {
  grid-area: box3;
}

.box4 {
  grid-area: box4;
}

.dynamicAndCircleBox {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 16px;

  .overflowHidden {
    overflow: hidden;
  }

  > div:first-child {
    max-width: 100%;
    overflow: hidden;

    @media screen and (min-width: 2660px) {
      max-width: 84vw;
    }
  }

  .menuTab {
    margin-left: 12px;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 1440px) {
    flex-direction: column;
  }
}

.dynamicAndLinearBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 16px;
}

.topProductsBox {
}

.noDataMessage {
  position: absolute;
  backdrop-filter: blur(4px);
  background-color: rgba(white, 0.2);
  width: 100%;
  height: 100%;
  padding: 16px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 3px;
  z-index: 12;
  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 35px;
    background-color: rgba(white, 1);
    backdrop-filter: blur(7px);
    width: 70%;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    margin-top: 100px;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 959px) {
      width: 90%;
    }
    @media screen and (max-width: 599px) {
      width: 100%;
      padding: 16px;
    }

    .text {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      color: #43455c;
      line-height: 1.1em;
    }
  }
}

.loader {
  @include loader;
}

.boxLoader {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  min-height: 100px;
}

.tableWrap {
  position: relative;
}

.widgetSize {
  min-height: 242px;
}

.dynamicSize {
  min-height: 380px;
}

.topSize {
  min-width: 490px;
  min-height: 100px;
  height: 100%;

  @media screen and (max-width: 550px) {
    min-width: 100%;
  }
}
