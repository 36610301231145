@import "root/css/variables.scss";

.dialogTitle {
	font-weight: 500;

	.icon {
		width: 0.8em;
		height: 0.8em;
		margin-left: -2px;
		margin-right: 4px;
		color: inherit;
		display: inline-block;
	}
}

.name {
	cursor: pointer;
}
