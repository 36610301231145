.diagramDecription {
	width: 250px;
	padding-left: 10px;

	border: 1px solid;
	background-color: white;
	
}


.configCircle .recharts-surface{
	width: 250px !important;
	height: 250px !important;
}
