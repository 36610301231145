.menuTab {
  // background-color: white;
  padding: 10px 12px;
}

.pagesContainer {
  display: flex;
  flex-direction: column;
  //gap: 10px;
}
